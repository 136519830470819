import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  Controller,
  FormContainer,
  MultiSelectElement,
  SelectElement,
  TextFieldElement,
  useForm,
} from "react-hook-form-mui";
import { earlyComplicationOptions, FilterItem, pathologyOptions } from "../Pages/Report/Report";
import { FilterIcon } from "../icon";
import { labelForm, spacingForm } from "../style";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { masterDataService } from "../service/masterDataService";
import { max, min, set } from "date-fns";
import { watch } from "fs";

type Props = {
  filter?: FilterItem;
  reportType: string;
  isHideFilter: boolean;
  onApplyFilter: (newFilter: FilterItem) => void;
  setReportType: (reportType: string) => void;
  setFilter: (value: FilterItem | undefined) => void;
  setIsHideFilter: (value: boolean) => void;
};

type DisplayFilterProps = {
  label: string;
  value: any;
};

type DisplayRangeFilterProps = {
  label: string;
  minValue: any;
  maxValue: any;
};

const DisplayFilter = ({ label, value }: DisplayFilterProps) => {
  if (!shouldDisplayValue(value)) return null;

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      gap={1}
      sx={{ fontSize: 14 }}
      display={"grid"}
      gridTemplateColumns={"1fr 1.5fr "}
    >
      <Box
        sx={{
          borderRadius: 1,
          bgcolor: "#dbdbdb",
          px: 1,
          py: 0.5,
          textAlign: "center",
          boxSizing: "border-box",
        }}
      >
        {label}
      </Box>
      <Box>{Array.isArray(value) ? value.join(", ") : value}</Box>
    </Stack>
  );
};

const DisplayRangeFilter = ({
  label,
  minValue,
  maxValue,
}: DisplayRangeFilterProps) => {
  if (!shouldDisplayValue(minValue) && !shouldDisplayValue(maxValue)) {
    return null;
  }

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      gap={1}
      sx={{ fontSize: 14 }}
      display={"grid"}
      gridTemplateColumns={"1fr 1.5fr "}
    >
      <Box
        sx={{
          borderRadius: 1,
          bgcolor: "#dbdbdb",
          px: 1,
          py: 0.5,
          textAlign: "center",
          boxSizing: "border-box",
        }}
      >
        {label}
      </Box>
      <Box>
        {shouldDisplayValue(minValue) ? (
          <>
            {maxValue ? "" : "มากกว่า "}
            {minValue ?? "-"}
            {shouldDisplayValue(maxValue) && " - "}
          </>
        ) : null}
        {shouldDisplayValue(maxValue) ? (
          <>
            {minValue ? "" : "น้อยกว่า "}
            {maxValue ?? "-"}
          </>
        ) : null}
      </Box>
    </Stack>
  );
};

const shouldDisplayValue = (value: any) => {
  if (Array.isArray(value)) {
    return value.length > 0;
  }
  return value !== undefined && value !== "";
};

// const getGridItemSize = (visibleCount: number) => {
//   const maxColumns = 3;
//   const columns = Math.min(visibleCount, maxColumns);
//   return 12 / columns;
// };

export const ReportFiltering = ({
  filter,
  reportType,
  isHideFilter,
  onApplyFilter,
  setReportType,
  setFilter,
  setIsHideFilter,
}: Props) => {
  const [reportTypeTemp, setReportTypeTemp] = useState("");
  const [minDate, setMinDate] = useState<Dayjs | null>(null);
  const [maxDate, setMaxDate] = useState<Dayjs | null>(null);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [complicationOptions, setComplicationOptions] = useState<string[]>([]);
  const { control, setValue, watch } = useForm<FilterItem>({
    defaultValues: {
      unComorbidities: filter?.unComorbidities ?? ["ทั้งหมด"],
      comorbidities: filter?.comorbidities ?? ["ทั้งหมด"],
      complication: filter?.complication ?? ["ทั้งหมด"],
      pathology: filter?.pathology ?? ["ทั้งหมด"],
      // age: filter?.age ?? ["ทั้งหมด"],
    },
  });
  const unComorbidities = watch("unComorbidities");
  const comorbidities = watch("comorbidities");
  const complication = watch("complication");
  const pathology = watch("pathology");
  // const age = watch("age");
  const formMethods = useForm<FilterItem>({
    defaultValues: {
      sex: filter?.sex ?? "ทั้งหมด",
      // age: filter?.age ?? ["ทั้งหมด"],
      minAge: filter?.minAge ?? undefined,
      maxAge: filter?.maxAge ?? undefined,
      minWeightFirstVisitValue: filter?.minWeightFirstVisitValue ?? undefined,
      maxWeightFirstVisitValue: filter?.maxWeightFirstVisitValue ?? undefined,
      minWeightCurrentValue: filter?.minWeightCurrentValue ?? undefined,
      maxWeightCurrentValue: filter?.maxWeightCurrentValue ?? undefined,
      minHeight: filter?.minHeight ?? undefined,
      maxHeight: filter?.maxHeight ?? undefined,
      minBmiFirstVisit: filter?.minBmiFirstVisit ?? undefined,
      maxBmiFirstVisit: filter?.maxBmiFirstVisit ?? undefined,
      minBmiCurrent: filter?.minBmiFirstVisit ?? undefined,
      maxBmiCurrent: filter?.maxBmiFirstVisit ?? undefined,
      comorbidities: comorbidities ?? ["ทั้งหมด"],
      surgeryType: filter?.surgeryType ?? "ทั้งหมด",
      // surgeryNo: filter?.surgeryNo ?? undefined,
      surgeryCount: filter?.surgeryCount ?? "ทั้งหมด",
      pwl: filter?.pwl ?? "ทั้งหมด",
      ewl: filter?.ewl ?? "ทั้งหมด",
      twl: filter?.twl ?? "ทั้งหมด",
      // minPwl: filter?.minPwl ?? undefined,
      // maxPwl: filter?.maxPwl ?? undefined,
      ultrasound: filter?.ultrasound ?? "ทั้งหมด",
      endoscopy: filter?.endoscopy ?? "ทั้งหมด",
      fibroScan: filter?.fibroScan ?? "ทั้งหมด",
      unComorbidities: unComorbidities ?? ["ทั้งหมด"],
      complication: complication ?? ["ทั้งหมด"],
      followUp: filter?.followUp ?? "ทั้งหมด",
      pathology: pathology ?? ["ทั้งหมด"],
    },
  });
  const minAgeValue = formMethods.watch("minAge");
  const maxAgeValue = formMethods.watch("maxAge");
  const minHeightValue = formMethods.watch("minHeight");
  const maxHeightValue = formMethods.watch("maxHeight");
  const minWeightFirstVisitValue = formMethods.watch(
    "minWeightFirstVisitValue"
  );
  const maxWeightFirstVisitValue = formMethods.watch(
    "maxWeightFirstVisitValue"
  );
  const minWeightCurrentValue = formMethods.watch("minWeightCurrentValue");
  const maxWeightCurrentValue = formMethods.watch("maxWeightCurrentValue");
  const minBmiFirstVisit = formMethods.watch("minBmiFirstVisit");
  const maxBmiFirstVisit = formMethods.watch("maxBmiFirstVisit");
  const minBmiCurrent = formMethods.watch("minBmiCurrent");
  const maxBmiCurrent = formMethods.watch("maxBmiCurrent");
  // const minPwlValue = formMethods.watch("minPwl");
  // const maxPwlValue = formMethods.watch("maxPwl");

  const handleFilterClick = () => {
    if (filter) {
      setMinDate(filter.minDate ? dayjs(filter.minDate) : null);
      setMaxDate(filter.maxDate ? dayjs(filter.maxDate) : null);
    }
    setOpenFilterDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenFilterDialog(false);
  };

  const handleCancelDialog = () => {
    if(filter){
      Object.keys(filter).forEach((key) => {
        formMethods.setValue(key as keyof FilterItem, filter[key as keyof FilterItem]);
      });
      setValue("minDate", filter.minDate);
      setValue("maxDate", filter.maxDate);
      setValue("unComorbidities", filter.unComorbidities);
      setValue("comorbidities", filter.comorbidities);
      setValue("complication", filter.complication);
      setValue("pathology", filter.pathology);
      // setValue("age", filter.age);
    }else {
      formMethods.reset();
      setMinDate(null);
      setMaxDate( null);
      setValue("unComorbidities", complicationOptions);
      setValue("comorbidities", complicationOptions);
      setValue("complication", earlyComplicationOptions);
      setValue("pathology", pathologyOptions);
      // setValue("age", ageOptions);
    }
    setOpenFilterDialog(false);
  };

  
  const handleSubmit = (data: FilterItem) => {
    const formattedMinDate = dayjs(minDate).format("YYYY-MM-DD");
    const formattedMaxDate = dayjs(maxDate).format("YYYY-MM-DD");

    formMethods.setValue("minDate", minDate ? formattedMinDate : "");
    formMethods.setValue("maxDate", maxDate ? formattedMaxDate : "");
    const formattedData = {
      ...data,
      minDate: minDate ? formattedMinDate : "",
      maxDate: maxDate ? formattedMaxDate : "",
    };
    if(data.unComorbidities.length === 0 ){
      formattedData.unComorbidities = ["ทั้งหมด"];
      setValue("unComorbidities", complicationOptions);
    }
    if(data.comorbidities.length === 0 ){
      formattedData.comorbidities = ["ทั้งหมด"];
      setValue("comorbidities",complicationOptions);
    }
    if(data.complication.length === 0 ){
      formattedData.complication = ["ทั้งหมด"];
      setValue("complication", earlyComplicationOptions);
    }
    if(data.pathology.length === 0 ){
      formattedData.pathology = ["ทั้งหมด"];
      setValue("pathology", pathologyOptions);
    }
    // if(data.age.length === 0 ){
    //   formattedData.age = ["ทั้งหมด"];
    //   setValue("age", ageOptions);
    // }
    onApplyFilter(formattedData);
    handleCloseDialog();
  };
  const validateMinValue = (value: number, maxValue: number) => {
    if (value > 0 && maxValue > 0) {
      if (value > maxValue) return "ค่าเริ่มต้น ต้องน้อยกว่า ค่าสิ้นสุด";
      return true;
    }
  };

  const validateMaxValue = (value: number, minValue: number) => {
    if (value > 0 && minValue > 0) {
      if (value < minValue) return "ค่าสิ้นสุด ต้องมากกว่า ค่าเริ่มต้น";
      return true;
    }
  };
  const clearForm = () => {
    formMethods.reset();
    setValue("unComorbidities", complicationOptions);
    setValue("comorbidities", complicationOptions);
    setValue("complication", earlyComplicationOptions);
    setValue("pathology", pathologyOptions);
    // setValue("age", ageOptions);
    setMinDate(null);
    setMaxDate(null);
  };
  const visibleCount = fieldOrder.reduce((acc, key) => {
    if (filter && shouldDisplayValue(filter[key])) {
      return acc + 1;
    }
    return acc;
  }, 0);

  // const gridItemSize = getGridItemSize(visibleCount);
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const ageOptions = [
    "น้อยกว่า 20 ปี",
    "21-30 ปี",
    "31-40 ปี",
    "41-50 ปี",
    "51-60 ปี",
    "61-70 ปี",
    "มากกว่า 70 ปี",
  ];

  useEffect(() => {
    const fetchComplicationOptions = async () => {
      try {
        const data = await masterDataService.getComplicationMasterData();
        setComplicationOptions(data.map((item: any) => item.name)); // Adjust the mapping as necessary
      } catch (error) {
        console.error("Failed to fetch complication options", error);
      }
    };

    fetchComplicationOptions();
  }, []);


  useEffect(() => {
    const fetchComplicationOptions = async () => {
      try {
        const data = await masterDataService.getComplicationMasterData();
        const options = data.map((item: any) => item.name);
        setComplicationOptions(options);
        // Set all options as default
        setValue("unComorbidities", options); 
        setValue("comorbidities", options);
        setValue("complication", earlyComplicationOptions); 
        setValue("pathology", pathologyOptions);
        // setValue("age", ageOptions); 
      } catch (error) {
        console.error("Failed to fetch complication options", error);
      }
    };
    fetchComplicationOptions();
  }, [setValue]);

  const handleUncomorbiditiesSelectChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as string[];
    if (value.includes("ทั้งหมด")) {
      if (unComorbidities.length === complicationOptions.length) {
        setValue("unComorbidities", []);
        formMethods.setValue("unComorbidities", []);
      } else {
        setValue("unComorbidities", complicationOptions);
        formMethods.setValue("unComorbidities", ["ทั้งหมด"]);
      }
    } else {
      setValue("unComorbidities", value);
      formMethods.setValue("unComorbidities", value);
    }
  };

  const handleComorbiditiesSelectChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as string[];
    if (value.includes("ทั้งหมด")) {
      if (comorbidities.length === complicationOptions.length) {
        setValue("comorbidities", []);
        formMethods.setValue("comorbidities", []);
      } else {
        setValue("comorbidities", complicationOptions);
        formMethods.setValue("comorbidities", ["ทั้งหมด"]);
      }
    } else {
      setValue("comorbidities", value);
      formMethods.setValue("comorbidities", value);
    }
  };

  const handleEarlyComplicationSelectChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as string[];
    if (value.includes("ทั้งหมด")) {
      if (complication.length === earlyComplicationOptions.length) {
        setValue("complication", []);
        formMethods.setValue("complication", []);
      } else {
        setValue("complication", earlyComplicationOptions);
        formMethods.setValue("complication", ["ทั้งหมด"]);
      }
    } else {
      setValue("complication", value);
      formMethods.setValue("complication", value);
    }
  };

  const handlePathologySelectChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as string[];
    if (value.includes("ทั้งหมด")) {
      if (pathology.length === pathologyOptions.length) {
        setValue("pathology", []);
        formMethods.setValue("pathology", []);
      } else {
        setValue("pathology", pathologyOptions);
        formMethods.setValue("pathology", ["ทั้งหมด"]);
      }
    } else {
      setValue("pathology", value);
      formMethods.setValue("pathology", value);
    }
  };

  // const handleAgeSelectChange = (event: SelectChangeEvent<string[]>) => {
  //   const value = event.target.value as string[];
  //   if (value.includes("ทั้งหมด")) {
  //     if (age.length === ageOptions.length) {
  //       setValue("age", []);
  //       formMethods.setValue("age", []);
  //     } else {
  //       setValue("age", ageOptions);
  //       formMethods.setValue("age", ["ทั้งหมด"]);
  //     }
  //   } else {
  //     setValue("age", value);
  //     formMethods.setValue("age", value);
  //   }
  // };

  return (
    <>
      <Box sx={{ bgcolor: "white" }}>
        <Box
          sx={{
            borderBottom: "2px solid #D8D8D8",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            component={"span"}
            sx={{
              display: "inline-block",
              minWidth: "185px",
              borderBottom: "4px solid #098484",
              p: 1.5,
              fontSize: 18,
              fontWeight: 600,
              ml: 1.5,
              boxSizing: "border-box",
            }}
          >
            กำหนดเงื่อนไขการออกรายงาน
          </Box>
          <Box id="filter-button">
            <Button
              variant="contained"
              sx={{ m: "8px" }}
              onClick={handleFilterClick}
            >
              <FilterIcon sx={{ color: "white" }} />
              Filter
            </Button>
            {filter && (
              <Button
                variant="contained"
                sx={{ m: "8px", ml: 0 }}
                style={{ backgroundColor: "#7A8688", color: "white" }}
                onClick={() => {
                  setReportType("");
                  setFilter(undefined);
                  clearForm();
                }}
              >
                ล้าง Filter
              </Button>
            )}
          </Box>
        </Box>
        <Box id="filter-display" sx={{ p: "20px" }}>
          {filter ? (
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography component={"span"} fontSize={16} fontWeight={500}>
                {`เงื่อนไข จำนวนที่เลือก (${visibleCount} รายการ)`}
              </Typography>
              <Button
                variant="text"
                onClick={() => setIsHideFilter(!isHideFilter)}
              >
                {isHideFilter ? "กดเพื่อแสดง" : "กดเพื่อซ่อน"}
              </Button>
            </Stack>
          ) : (
            <Typography>{`เลือก Filter ที่ต้องการค้นหา`}</Typography>
          )}
          {filter && (
            <Box mt={1} display={isHideFilter ? "none" : ""}>
              <Grid container spacing={2}>
                {fieldOrder.map((key) => {
                  const value = filter[key];

                  if (key.startsWith("min")) {
                    const minKey = key;
                    const maxKey = key.replace(
                      "min",
                      "max"
                    ) as keyof FilterItem;
                    const minValue = filter[key];
                    const maxValue = filter[maxKey];
                    if (
                      shouldDisplayValue(minValue) ||
                      shouldDisplayValue(maxValue)
                    ) {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          key={`${key}-${maxKey}`}
                        >
                          <DisplayRangeFilter
                            label={fieldLabels[key]}
                            minValue={minValue}
                            maxValue={maxValue}
                          />
                        </Grid>
                      );
                    }
                    // return (
                    //   <Grid
                    //     item
                    //     xs={12}
                    //     sm={6}
                    //     md={4}
                    //     key={`${minKey}-${maxKey}`}
                    //   >
                    //     <DisplayRangeFilter
                    //       label={fieldLabels[minKey]}
                    //       minValue={filter[minKey]}
                    //       maxValue={filter[maxKey]}
                    //     />
                    //   </Grid>
                    // );
                  }
                  if (!shouldDisplayValue(value)) return null;
                  return (
                    <Grid item xs={12} sm={6} md={4} key={key}>
                      <DisplayFilter label={fieldLabels[key]} value={value} />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          )}
          {filter && (
            <Stack direction={"row"} gap={2} mt={2}>
              <Select
                value={reportTypeTemp}
                size="small"
                onChange={(e) => setReportTypeTemp(e.target.value)}
                sx={{ fontSize: "14px", width: 350 }}
                MenuProps={{
                  sx: {
                    "& .MuiMenuItem-root": {
                      fontSize: "14px",
                    },

                    left: 50,
                    top: 50,
                    minWidth: "0 !important",
                    width: "350px !important",
                  },
                }}
                displayEmpty
              >
                <MenuItem disabled value={""} sx={{ fontSize: 14 }}>
                  โปรดระบุ
                </MenuItem>
                {reportList.map((item) => {
                  return (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      disabled={item.id === "RPT03" || item.id === "RPT05"}
                    >
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#098484",
                  marginRight: "10px",
                  height: "40px",
                  width: "150px",
                }}
                onClick={() => setReportType(reportTypeTemp)}
              >
                ค้นหา
              </Button>
            </Stack>
          )}
        </Box>
      </Box>
      {/* Filter Dialog */}
      <Dialog open={openFilterDialog} onClose={handleCloseDialog} fullWidth>
        <DialogTitle>เลือก Filter ที่ต้องการค้นหา</DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom style={{ color: "#646D78" }}>
            กรุณาระบุเงื่อนไขที่ต้องการค้นหา
          </Typography>
          <FormContainer formContext={formMethods} onSuccess={handleSubmit}>
            <Stack spacing={2} sx={{ mt: 2, ...labelForm }}>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>ช่วงที่ค้นหาข้อมูล</Typography>
                <Stack
                  direction={"row"}
                  gap={2}
                  alignItems={"center"}
                  width={1}
                >
                  <DatePicker
                    value={minDate}
                    maxDate={dayjs()}
                    onChange={(newValue) => setMinDate(newValue)}
                    sx={{ width: 1 }}
                  />
                  -
                  <DatePicker
                    value={maxDate}
                    minDate={minDate ?? undefined}
                    maxDate={dayjs()}
                    onChange={(newValue) => setMaxDate(newValue)}
                    sx={{ width: 1 }}
                  />
                </Stack>
              </Stack>{" "}
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>ชื่อ-นามสกุล</Typography>
                <TextFieldElement
                  name="name"
                  variant="outlined"
                  size="small"
                  type="text"
                  // label="-- ชื่อ-นามสกุล --"
                  sx={{ width: "inherit" }}
                />
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>เพศ</Typography>
                <SelectElement
                  name="sex"
                  size="small"
                  options={[
                    {
                      id: "",
                      label: "- โปรดระบุข้อมูล --",
                      disabled: true,
                    },
                    {
                      id: "ทั้งหมด",
                      label: "ทั้งหมด",
                    },
                    {
                      id: "ชาย (Male)",
                      label: "ชาย (Male)",
                    },
                    {
                      id: "หญิง (Female)",
                      label: "หญิง (Female)",
                    },
                  ]}
                  sx={{
                    minWidth: "150px",
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          zoom: `${100 * (100 / 85)}%`,
                          top: "-15px",
                          "& .MuiMenuItem-root": {
                            fontSize: "14px",
                          },
                          minWidth: "0 !important",
                          width: "450px !important",
                        },
                      },
                    },
                  }}
                />
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>อายุ</Typography>
                <Stack
                  direction={"row"}
                  gap={2}
                  alignItems={"center"}
                  width={1}
                >
                  <TextFieldElement
                    name="minAge"
                    variant="outlined"
                    size="small"
                    type="number"
                    label="-- ระบุอายุเริ่มต้น --"
                    sx={{ width: "inherit" }}
                    required={Boolean(maxAgeValue)}
                    rules={{
                      validate: (value) => validateMinValue(value, maxAgeValue),
                    }}
                    inputProps={{ min: 0 }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      target.value = target.value.replace(/[^0-9]/g, "");
                      if (parseInt(target.value) < 0) {
                        target.value = "";
                      }
                    }}
                  />
                  -
                  <TextFieldElement
                    name="maxAge"
                    variant="outlined"
                    size="small"
                    type="number"
                    label="-- ระบุอายุสิ้นสุด --"
                    sx={{ width: "inherit" }}
                    // required={Boolean(minAgeValue)}
                    rules={{
                      validate: (value) => validateMaxValue(value, minAgeValue),
                    }}
                    inputProps={{ min: 0 }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      target.value = target.value.replace(/[^0-9]/g, "");
                      if (parseInt(target.value) < 0) {
                        target.value = "";
                      }
                    }}
                  />
                </Stack> 
                {/* <Stack
                  direction={"row"}
                  gap={2}
                  alignItems={"center"}
                  width={1}
                >
                  <TextFieldElement
                    name="minAge"
                    variant="outlined"
                    size="small"
                    type="number"
                    label="-- ระบุอายุเริ่มต้น --"
                    sx={{ width: "inherit" }}
                    // required={Boolean(maxAgeValue)}
                    rules={{
                      validate: (value) => validateMinValue(value, maxAgeValue),
                    }}
                    inputProps={{ min: 0 }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      target.value = target.value.replace(/[^0-9]/g, "");
                      if (parseInt(target.value) < 0) {
                        target.value = "";
                      }
                    }}
                  />
                  -
                  <TextFieldElement
                    name="maxAge"
                    variant="outlined"
                    size="small"
                    type="number"
                    label="-- ระบุอายุสิ้นสุด --"
                    sx={{ width: "inherit" }}
                    // required={Boolean(minAgeValue)}
                    rules={{
                      validate: (value) => validateMaxValue(value, minAgeValue),
                    }}
                    inputProps={{ min: 0 }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      target.value = target.value.replace(/[^0-9]/g, "");
                      if (parseInt(target.value) < 0) {
                        target.value = "";
                      }
                    }}
                  />
                </Stack> */}
              </Stack>
              {/* <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>ส่วนสูง (cm.)</Typography>
                <Stack
                  direction={"row"}
                  gap={2}
                  alignItems={"center"}
                  width={1}
                >
                  <TextFieldElement
                    name="minHeight"
                    variant="outlined"
                    size="small"
                    type="number"
                    label="-- ระบุส่วนสูงเริ่มต้น --"
                    sx={{ width: "inherit" }}
                    // required={Boolean(maxHeightValue)}
                    rules={{
                      validate: (value) =>
                        validateMinValue(value, maxHeightValue),
                    }}
                    inputProps={{
                      min: 0,
                    }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      const value = target.value;
                      if (value.includes(".")) {
                        const [integerPart, decimalPart] = value.split(".");
                        if (decimalPart.length > 2) {
                          target.value = `${integerPart}.${decimalPart.slice(
                            0,
                            2
                          )}`;
                        }
                      }
                      if (parseFloat(value) < 0) {
                        target.value = "";
                      }
                    }}
                  />
                  -
                  <TextFieldElement
                    name="maxHeight"
                    variant="outlined"
                    size="small"
                    type="number"
                    label="-- ระบุส่วนสูงสิ้นสุด --"
                    sx={{ width: "inherit" }}
                    // required={Boolean(minHeightValue)}
                    rules={{
                      validate: (value) =>
                        validateMaxValue(value, minHeightValue),
                    }}
                    inputProps={{ min: 0 }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      const value = target.value;
                      if (value.includes(".")) {
                        const [integerPart, decimalPart] = value.split(".");
                        if (decimalPart.length > 2) {
                          target.value = `${integerPart}.${decimalPart.slice(
                            0,
                            2
                          )}`;
                        }
                      }
                      if (parseFloat(value) < 0) {
                        target.value = "";
                      }
                    }}
                  />
                </Stack>
              </Stack> */}
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>
                  น้ำหนัก First visit (kg.)
                </Typography>
                <Stack
                  direction={"row"}
                  gap={2}
                  alignItems={"center"}
                  width={1}
                >
                  <TextFieldElement
                    name="minWeightFirstVisitValue"
                    variant="outlined"
                    size="small"
                    type="number"
                    label="-- ระบุน้ำหนักเริ่มต้น --"
                    sx={{ width: "inherit" }}
                    required={Boolean(maxWeightFirstVisitValue)}
                    rules={{
                      validate: (value) =>
                        validateMinValue(value, maxWeightFirstVisitValue),
                    }}
                    inputProps={{ min: 0 }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      const value = target.value;
                      if (value.includes(".")) {
                        const [integerPart, decimalPart] = value.split(".");
                        if (decimalPart.length > 2) {
                          target.value = `${integerPart}.${decimalPart.slice(
                            0,
                            2
                          )}`;
                        }
                      }
                      if (parseFloat(value) < 0) {
                        target.value = "";
                      }
                    }}
                  />
                  -
                  <TextFieldElement
                    name="maxWeightFirstVisitValue"
                    variant="outlined"
                    size="small"
                    type="number"
                    label="-- ระบุน้ำหนักสิ้นสุด --"
                    sx={{ width: "inherit" }}
                    required={Boolean(minWeightFirstVisitValue)}
                    rules={{
                      validate: (value) =>
                        validateMaxValue(value, minWeightFirstVisitValue),
                    }}
                    inputProps={{ min: 0 }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      const value = target.value;
                      if (value.includes(".")) {
                        const [integerPart, decimalPart] = value.split(".");
                        if (decimalPart.length > 2) {
                          target.value = `${integerPart}.${decimalPart.slice(
                            0,
                            2
                          )}`;
                        }
                      }
                      if (parseFloat(value) < 0) {
                        target.value = "";
                      }
                    }}
                  />
                </Stack>
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>น้ำหนักปัจจุบัน (kg.)</Typography>
                <Stack
                  direction={"row"}
                  gap={2}
                  alignItems={"center"}
                  width={1}
                >
                  <TextFieldElement
                    name="minWeightCurrentValue"
                    variant="outlined"
                    size="small"
                    type="number"
                    label="-- ระบุน้ำหนักเริ่มต้น --"
                    sx={{ width: "inherit" }}
                    // required={Boolean(maxWeightValue)}
                    rules={{
                      validate: (value) =>
                        validateMinValue(value, maxWeightCurrentValue),
                    }}
                    inputProps={{ min: 0 }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      const value = target.value;
                      if (value.includes(".")) {
                        const [integerPart, decimalPart] = value.split(".");
                        if (decimalPart.length > 2) {
                          target.value = `${integerPart}.${decimalPart.slice(
                            0,
                            2
                          )}`;
                        }
                      }
                      if (parseFloat(value) < 0) {
                        target.value = "";
                      }
                    }}
                  />
                  -
                  <TextFieldElement
                    name="maxWeightCurrentValue"
                    variant="outlined"
                    size="small"
                    type="number"
                    label="-- ระบุน้ำหนักสิ้นสุด --"
                    sx={{ width: "inherit" }}
                    // required={Boolean(minWeightValue)}
                    rules={{
                      validate: (value) =>
                        validateMaxValue(value, minWeightCurrentValue),
                    }}
                    inputProps={{ min: 0 }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      const value = target.value;
                      if (value.includes(".")) {
                        const [integerPart, decimalPart] = value.split(".");
                        if (decimalPart.length > 2) {
                          target.value = `${integerPart}.${decimalPart.slice(
                            0,
                            2
                          )}`;
                        }
                      }
                      if (parseFloat(value) < 0) {
                        target.value = "";
                      }
                    }}
                  />
                </Stack>
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>BMI (First visit)</Typography>
                <Stack
                  direction={"row"}
                  gap={2}
                  alignItems={"center"}
                  width={1}
                >
                  <TextFieldElement
                    name="minBmiFirstVisit"
                    variant="outlined"
                    size="small"
                    type="number"
                    label="-- ระบุBMIเริ่มต้น --"
                    sx={{ width: "inherit" }}
                    // required={Boolean(maxBmiValue)}
                    rules={{
                      validate: (value) =>
                        validateMinValue(value, maxBmiFirstVisit),
                    }}
                    inputProps={{ min: 0 }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      const value = target.value;
                      if (value.includes(".")) {
                        const [integerPart, decimalPart] = value.split(".");
                        if (decimalPart.length > 2) {
                          target.value = `${integerPart}.${decimalPart.slice(
                            0,
                            2
                          )}`;
                        }
                      }
                      if (parseFloat(value) < 0) {
                        target.value = "";
                      }
                    }}
                  />
                  -
                  <TextFieldElement
                    name="maxBmiFirstVisit"
                    variant="outlined"
                    size="small"
                    type="number"
                    label="-- ระบุBMIสิ้นสุด --"
                    sx={{ width: "inherit" }}
                    // required={Boolean(minBmiValue)}
                    rules={{
                      validate: (value) =>
                        validateMaxValue(value, minBmiFirstVisit),
                    }}
                    inputProps={{ min: 0 }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      const value = target.value;
                      if (value.includes(".")) {
                        const [integerPart, decimalPart] = value.split(".");
                        if (decimalPart.length > 2) {
                          target.value = `${integerPart}.${decimalPart.slice(
                            0,
                            2
                          )}`;
                        }
                      }
                      if (parseFloat(value) < 0) {
                        target.value = "";
                      }
                    }}
                  />
                </Stack>
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>BMI (ปัจจุบัน)</Typography>
                <Stack
                  direction={"row"}
                  gap={2}
                  alignItems={"center"}
                  width={1}
                >
                  <TextFieldElement
                    name="minBmiCurrent"
                    variant="outlined"
                    size="small"
                    type="number"
                    label="-- ระบุBMIเริ่มต้น --"
                    sx={{ width: "inherit" }}
                    // required={Boolean(maxBmiValue)}
                    rules={{
                      validate: (value) =>
                        validateMinValue(value, maxBmiCurrent),
                    }}
                    inputProps={{ min: 0 }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      const value = target.value;
                      if (value.includes(".")) {
                        const [integerPart, decimalPart] = value.split(".");
                        if (decimalPart.length > 2) {
                          target.value = `${integerPart}.${decimalPart.slice(
                            0,
                            2
                          )}`;
                        }
                      }
                      if (parseFloat(value) < 0) {
                        target.value = "";
                      }
                    }}
                  />
                  -
                  <TextFieldElement
                    name="maxBmiCurrent"
                    variant="outlined"
                    size="small"
                    type="number"
                    label="-- ระบุBMIสิ้นสุด --"
                    sx={{ width: "inherit" }}
                    // required={Boolean(minBmiValue)}
                    rules={{
                      validate: (value) =>
                        validateMaxValue(value, minBmiCurrent),
                    }}
                    inputProps={{ min: 0 }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      const value = target.value;
                      if (value.includes(".")) {
                        const [integerPart, decimalPart] = value.split(".");
                        if (decimalPart.length > 2) {
                          target.value = `${integerPart}.${decimalPart.slice(
                            0,
                            2
                          )}`;
                        }
                      }
                      if (parseFloat(value) < 0) {
                        target.value = "";
                      }
                    }}
                  />
                </Stack>
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>
                  โรคประจำตัวและภาวะแทรกซ้อน (First Visit)
                </Typography>
                <Controller
                  name="comorbidities"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      multiple
                      renderValue={(selected) =>
                        comorbidities.length === complicationOptions.length
                          ? "ทั้งหมด"
                          : (selected as string[]).join(", ")
                      }
                      onChange={handleComorbiditiesSelectChange}
                      sx={{ height: 40 }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            zoom: `${100 * (100 / 85)}%`,
                            top: "-15px",
                            "& .MuiMenuItem-root": {
                              fontSize: "14px",
                              padding: "2px 5px", // Adjust the padding here to reduce space between options
                            },
                            minWidth: "0 !important",
                            width: "450px !important", // Adjust the width here
                            maxHeight: "200px",
                          },
                        },
                      }}
                    >
                      <MenuItem value="ทั้งหมด">
                        <Checkbox
                          checked={
                            comorbidities.length ===
                            complicationOptions.length
                          }
                        />
                        ทั้งหมด
                      </MenuItem>
                      {complicationOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          <Checkbox
                            checked={comorbidities.includes(option)}
                          />
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>ชนิดการผ่าตัด</Typography>
                <SelectElement
                  name="surgeryType"
                  size="small"
                  // label="-- เลือกชนิดการผ่าตัด --"
                  options={[
                    {
                      id: "",
                      label: "- โปรดระบุข้อมูล --",
                      disabled: true,
                    },
                    {
                      id: "ทั้งหมด",
                      label: "ทั้งหมด",
                    },
                    {
                      id: "Roux-en-Y gastric bypass (RYGB)",
                      label: "Roux-en-Y gastric bypass (RYGB)",
                    },
                    {
                      id: "Sleeve-DJB (Roux-en-Y)",
                      label: "Sleeve-DJB (Roux-en-Y)",
                    },
                    {
                      id: "Sleeve-PJB",
                      label: "Sleeve-PJB",
                    },
                    {
                      id: "Gastric Banding",
                      label: "Gastric Banding",
                    },
                    {
                      id: "Endoscopic Sleeve Gastroplasty",
                      label: "Endoscopic Sleeve Gastroplasty",
                    },
                    {
                      id: "Sleeve Gastrectomy (SG)",
                      label: "Sleeve Gastrectomy (SG)",
                    },
                    {
                      id: "Sleeve-DJB (Loop)",
                      label: "Sleeve-DJB (Loop)",
                    },
                    {
                      id: "SADI-S",
                      label: "SADI-S",
                    },
                    {
                      id: "Intragastric Balloon",
                      label: "Intragastric Balloon",
                    },
                  ]}
                  sx={{
                    minWidth: "150px",
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          zoom: `${100 * (100 / 85)}%`,
                          top: "-15px",
                          "& .MuiMenuItem-root": {
                            fontSize: "14px",
                          },
                          minWidth: "0 !important",
                          width: "450px !important",
                        },
                      },
                    },
                  }}
                />
              </Stack>
              {/* <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>ครั้งที่ผ่าตัด</Typography>
                <SelectElement
                  name="surgeryNo"
                  size="small"
                  label="-- เลือกMedication Use --"
                  options={[
                    {
                      id: 0,
                      label: "- โปรดระบุข้อมูล --",
                      disabled: true,
                    },
                    ...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((val) => ({
                      id: val.toString(),
                      label: `${val}`,
                    })),
                  ]}
                  sx={{
                    minWidth: "150px",
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          zoom: `${100 * (100 / 85)}%`,
                          top: "-15px",
                          "& .MuiMenuItem-root": {
                            fontSize: "14px",
                          },
                          minWidth: "0 !important",
                          width: "450px !important",
                        },
                      },
                    },
                  }}
                />
              </Stack> */}
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>จำนวนครั้งที่ผ่าตัด</Typography>
                <SelectElement
                  name="surgeryCount"
                  size="small"
                  // label="-- จำนวนครั้งที่ผ่าตัด --"
                  options={[
                    {
                      id: "",
                      label: "- โปรดระบุข้อมูล --",
                      disabled: true,
                    },
                    {
                      id: "ทั้งหมด",
                      label: "ทั้งหมด",
                    },
                    {
                      id: "1 ครั้ง",
                      label: "1 ครั้ง",
                    },
                    {
                      id: "2 ครั้ง",
                      label: "2 ครั้ง",
                    },
                    {
                      id: "มากกว่า 2 ครั้งขึ้นไป",
                      label: "มากกว่า 2 ครั้งขึ้นไป",
                    },
                  ]}
                  sx={{
                    minWidth: "150px",
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          zoom: `${100 * (100 / 85)}%`,
                          top: "-15px",
                          "& .MuiMenuItem-root": {
                            fontSize: "14px",
                          },
                          minWidth: "0 !important",
                          width: "450px !important",
                        },
                      },
                    },
                  }}
                />
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>Preop Weight Loss (%)</Typography>
                <SelectElement
                  name="pwl"
                  size="small"
                  // label="-- เลือกPreop Weight Loss --"
                  options={[
                    {
                      id: "",
                      label: "- โปรดระบุข้อมูล --",
                      disabled: true,
                    },
                    {
                      id: "ทั้งหมด",
                      label: "ทั้งหมด",
                    },
                    {
                      id: "<5",
                      label: "<5",
                    },
                    {
                      id: "5-10",
                      label: "5-10",
                    },
                    {
                      id: "10-15",
                      label: "10-15",
                    },
                    {
                      id: ">15",
                      label: ">15",
                    },
                  ]}
                  sx={{
                    minWidth: "150px",
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          zoom: `${100 * (100 / 85)}%`,
                          top: "-15px",
                          "& .MuiMenuItem-root": {
                            fontSize: "14px",
                          },
                          minWidth: "0 !important",
                          width: "450px !important",
                        },
                      },
                    },
                  }}
                />
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>EWL (%)</Typography>
                <SelectElement
                  name="ewl"
                  size="small"
                  // label="-- เลือกEWL --"
                  options={[
                    {
                      id: "",
                      label: "- โปรดระบุข้อมูล --",
                      disabled: true,
                    },
                    {
                      id: "ทั้งหมด",
                      label: "ทั้งหมด",
                    },
                    {
                      id: "<10",
                      label: "<10",
                    },
                    {
                      id: "10-30",
                      label: "10-30",
                    },
                    {
                      id: "30-50",
                      label: "30-50",
                    },
                    {
                      id: "50-75",
                      label: "50-75",
                    },
                    {
                      id: "75-100",
                      label: "75-100",
                    },
                    {
                      id: ">100",
                      label: ">100",
                    },
                  ]}
                  sx={{
                    minWidth: "150px",
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          zoom: `${100 * (100 / 85)}%`,
                          top: "-15px",
                          "& .MuiMenuItem-root": {
                            fontSize: "14px",
                          },
                          minWidth: "0 !important",
                          width: "450px !important",
                        },
                      },
                    },
                  }}
                />
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>TWL (%)</Typography>
                <SelectElement
                  name="twl"
                  size="small"
                  // label="-- เลือกTWL --"
                  options={[
                    {
                      id: "",
                      label: "- โปรดระบุข้อมูล --",
                      disabled: true,
                    },
                    {
                      id: "ทั้งหมด",
                      label: "ทั้งหมด",
                    },
                    {
                      id: "<5",
                      label: "<5",
                    },
                    {
                      id: "5-10",
                      label: "5-10",
                    },
                    {
                      id: "10-20",
                      label: "10-20",
                    },
                    {
                      id: "20-30",
                      label: "20-30",
                    },
                    {
                      id: ">30",
                      label: ">30",
                    },
                  ]}
                  sx={{
                    minWidth: "150px",
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          zoom: `${100 * (100 / 85)}%`,
                          top: "-15px",
                          "& .MuiMenuItem-root": {
                            fontSize: "14px",
                          },
                          minWidth: "0 !important",
                          width: "450px !important",
                        },
                      },
                    },
                  }}
                />
              </Stack>
              {/* <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>Preop Weight Loss (%)</Typography>
                <Stack
                  direction={"row"}
                  gap={2}
                  alignItems={"center"}
                  width={1}
                >
                  <TextFieldElement
                    name="minPwl"
                    variant="outlined"
                    size="small"
                    type="number"
                    label="-- ระบุ % เริ่มต้น --"
                    sx={{ width: "inherit" }}
                    // required={Boolean(maxPwlValue)}
                    rules={{
                      validate: (value) => validateMinValue(value, maxPwlValue),
                    }}
                    inputProps={{ min: 0 }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      const value = target.value;
                      if (value.includes(".")) {
                        const [integerPart, decimalPart] = value.split(".");
                        if (decimalPart.length > 2) {
                          target.value = `${integerPart}.${decimalPart.slice(
                            0,
                            2
                          )}`;
                        }
                      }
                      if (parseFloat(value) < 0) {
                        target.value = "";
                      }
                    }}
                  />
                  -
                  <TextFieldElement
                    name="maxPwl"
                    variant="outlined"
                    size="small"
                    type="number"
                    label="-- ระบุ % สิ้นสุด --"
                    sx={{ width: "inherit" }}
                    // required={Boolean(minPwlValue)}
                    rules={{
                      validate: (value) => validateMaxValue(value, minPwlValue),
                    }}
                    inputProps={{ min: 0 }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      const value = target.value;
                      if (value.includes(".")) {
                        const [integerPart, decimalPart] = value.split(".");
                        if (decimalPart.length > 2) {
                          target.value = `${integerPart}.${decimalPart.slice(
                            0,
                            2
                          )}`;
                        }
                      }
                      if (parseFloat(value) < 0) {
                        target.value = "";
                      }
                    }}
                  />
                </Stack>
              </Stack> */}
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>Ultrasound</Typography>
                <SelectElement
                  name="ultrasound"
                  size="small"
                  // label="-- เลือกUltrasound --"
                  options={[
                    {
                      id: "",
                      label: "- โปรดระบุข้อมูล --",
                      disabled: true,
                    },
                    {
                      id: "ทั้งหมด",
                      label: "ทั้งหมด",
                    },
                    {
                      id: "Fatty Liver",
                      label: "Fatty Liver",
                    },
                    {
                      id: "Gallstone",
                      label: "Gallstone",
                    },
                    {
                      id: "Cirrhosis",
                      label: "Cirrhosis",
                    },
                    {
                      id: "Ascites",
                      label: "Ascites",
                    },
                  ]}
                  sx={{
                    minWidth: "150px",
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          zoom: `${100 * (100 / 85)}%`,
                          top: "-15px",
                          "& .MuiMenuItem-root": {
                            fontSize: "14px",
                          },
                          minWidth: "0 !important",
                          width: "450px !important",
                        },
                      },
                    },
                  }}
                />
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>Endoscopy</Typography>
                <SelectElement
                  name="endoscopy"
                  size="small"
                  // label="-- เลือกEndoscopy --"
                  options={[
                    {
                      id: "",
                      label: "- โปรดระบุข้อมูล --",
                      disabled: true,
                    },
                    {
                      id: "ทั้งหมด",
                      label: "ทั้งหมด",
                    },
                    {
                      id: "Hiatal Hernia",
                      label: "Hiatal Hernia",
                    },
                    {
                      id: "Reflux Esophagitis",
                      label: "Reflux Esophagitis",
                    },
                    {
                      id: "Gastritis",
                      label: "Gastritis",
                    },
                    {
                      id: "Gastric/Duodenal Ulcer",
                      label: "Gastric/Duodenal Ulcer",
                    },
                    {
                      id: "H.Pyroli Infection",
                      label: "H.Pyroli Infection",
                    },
                  ]}
                  sx={{
                    minWidth: "150px",
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          zoom: `${100 * (100 / 85)}%`,
                          top: "-15px",
                          "& .MuiMenuItem-root": {
                            fontSize: "14px",
                          },
                          minWidth: "0 !important",
                          width: "450px !important",
                        },
                      },
                    },
                  }}
                />
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>FibroScan</Typography>
                <SelectElement
                  name="fibroScan"
                  size="small"
                  // label="-- เลือกFibroScan --"
                  options={[
                    {
                      id: "",
                      label: "- โปรดระบุข้อมูล --",
                      disabled: true,
                    },
                    {
                      id: "ทั้งหมด",
                      label: "ทั้งหมด",
                    },
                    {
                      id: "Yes",
                      label: "Yes",
                    },
                    {
                      id: "No",
                      label: "No",
                    },
                  ]}
                  sx={{
                    minWidth: "150px",
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          zoom: `${100 * (100 / 85)}%`,
                          top: "-15px",
                          "& .MuiMenuItem-root": {
                            fontSize: "14px",
                          },
                          minWidth: "0 !important",
                          width: "450px !important",
                        },
                      },
                    },
                  }}
                />
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>
                  ผลของโรคประจำตัวและภาวะแทรกซ้อน
                </Typography>
                <Controller
                  name="unComorbidities"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      multiple
                      renderValue={(selected) =>
                        unComorbidities.length === complicationOptions.length
                          ? "ทั้งหมด"
                          : (selected as string[]).join(", ")
                      }
                      onChange={handleUncomorbiditiesSelectChange}
                      sx={{ height: 40 }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            zoom: `${100 * (100 / 85)}%`,
                            top: "-15px",
                            "& .MuiMenuItem-root": {
                              fontSize: "14px",
                              padding: "2px 5px", // Adjust the padding here to reduce space between options
                            },
                            minWidth: "0 !important",
                            width: "450px !important", // Adjust the width here
                            maxHeight: "200px",
                          },
                        },
                      }}
                    >
                      <MenuItem value="ทั้งหมด">
                        <Checkbox
                          checked={
                            unComorbidities.length ===
                            complicationOptions.length
                          }
                        />
                        ทั้งหมด
                      </MenuItem>
                      {complicationOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          <Checkbox
                            checked={unComorbidities.includes(option)}
                          />
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>Early Complication</Typography>
                <Controller
                  name="complication"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      multiple
                      renderValue={(selected) =>
                        complication.length === earlyComplicationOptions.length
                          ? "ทั้งหมด"
                          : (selected as string[]).join(", ")
                      }
                      onChange={handleEarlyComplicationSelectChange}
                      sx={{ height: 40 }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            zoom: `${100 * (100 / 85)}%`,
                            top: "-15px",
                            "& .MuiMenuItem-root": {
                              fontSize: "14px",
                              padding: "2px 5px", // Adjust the padding here to reduce space between options
                            },
                            minWidth: "0 !important",
                            width: "450px !important", // Adjust the width here
                            maxHeight: "200px",
                          },
                        },
                      }}
                    >
                      <MenuItem value="ทั้งหมด">
                        <Checkbox
                          checked={
                            complication.length ===
                            earlyComplicationOptions.length
                          }
                        />
                        ทั้งหมด
                      </MenuItem>
                      {earlyComplicationOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          <Checkbox
                            checked={complication.includes(option)}
                          />
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {/* <SelectElement
                  name="complication"
                  size="small"
                  label="-- เลือกEarly Complication --"
                  options={[
                    {
                      id: "",
                      label: "- โปรดระบุข้อมูล --",
                      disabled: true,
                    },
                    {
                      id: "Leakage",
                      label: "Leakage",
                    },
                    {
                      id: "Bleeding",
                      label: "Bleeding",
                    },
                    {
                      id: "Surgical Site Infection",
                      label: "Surgical Site Infection",
                    },
                    {
                      id: "Pulmonary Complication",
                      label: "Pulmonary Complication",
                    },
                    {
                      id: "Obstruction",
                      label: "Obstruction",
                    },
                    {
                      id: "VTE",
                      label: "VTE",
                    },
                    {
                      id: "Cardiovascular events (MI, Stroke)",
                      label: "Cardiovascular events (MI, Stroke)",
                    },
                  ]}
                  sx={{
                    minWidth: "150px",
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          zoom: `${100 * (100 / 85)}%`,
                          top: "-15px",
                          "& .MuiMenuItem-root": {
                            fontSize: "14px",
                          },
                          minWidth: "0 !important",
                          width: "450px !important",
                        },
                      },
                    },
                  }}
                /> */}
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>ระยะเวลา Follow Up</Typography>
                <SelectElement
                  name="followUp"
                  size="small"
                  // label="-- เลือกระยะเวลา Follow Up --"
                  options={[
                    {
                      id: "",
                      label: "- โปรดระบุข้อมูล --",
                      disabled: true,
                    },
                    {
                      id: "ทั้งหมด",
                      label: "ทั้งหมด",
                    },
                    {
                      id: "0 - 1 month",
                      label: "0 - 1 month",
                    },
                    {
                      id: "1 - 3 months",
                      label: "1 - 3 months",
                    },
                    {
                      id: "3 - 6 months",
                      label: "3 - 6 months",
                    },
                    {
                      id: "6 - 12 months",
                      label: "6 - 12 months",
                    },
                    {
                      id: "2 years",
                      label: "2 years",
                    },
                    {
                      id: "3 years",
                      label: "3 years",
                    },
                    {
                      id: "4 years",
                      label: "4 years",
                    },
                    {
                      id: "5 years",
                      label: "5 years",
                    },
                    {
                      id: "6 years",
                      label: "6 years",
                    },
                    {
                      id: "7 years",
                      label: "7 years",
                    },
                    {
                      id: "8 years",
                      label: "8 years",
                    },
                    {
                      id: "9 years",
                      label: "9 years",
                    },
                    {
                      id: "10 years",
                      label: "10 years",
                    },
                    {
                      id: "10+ years",
                      label: "10+ years",
                    },
                  ]}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          zoom: `${100 * (100 / 85)}%`,
                          top: "-15px",
                          "& .MuiMenuItem-root": {
                            fontSize: "14px",
                          },
                          minWidth: "0 !important",
                          width: "450px !important",
                        },
                      },
                    },
                  }}
                />
                {/* <SelectElement
                  name="followUp"
                  size="small"
                  label="-- เลือกระยะเวลา Follow Up --"
                  options={[
                    {
                      id: "",
                      label: "- โปรดระบุข้อมูล --",
                      disabled: true,
                    },
                    ...[
                      "2WK",
                      "1M",
                      "3M",
                      "6M",
                      "9M",
                      "1Y",
                      "1.6Y",
                      "2Y",
                      "3Y",
                      "4Y",
                      "5Y",
                      "5Y+",
                    ].map((val) => ({
                      id: val,
                      label: `${val}`,
                    })),
                  ]}
                  sx={{
                    minWidth: "150px",
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          zoom: `${100 * (100 / 85)}%`,
                          top: "-15px",
                          "& .MuiMenuItem-root": {
                            fontSize: "14px",
                          },
                          minWidth: "0 !important",
                          width: "450px !important",
                          maxHeight: "200px",
                        },
                      },
                    },
                  }}
                /> */}
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>Pathology</Typography>
                <Controller
                  name="pathology"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      multiple
                      renderValue={(selected) =>
                        pathology.length === pathologyOptions.length
                          ? "ทั้งหมด"
                          : (selected as string[]).join(", ")
                      }
                      onChange={handlePathologySelectChange}
                      sx={{ height: 40 }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            zoom: `${100 * (100 / 85)}%`,
                            top: "-15px",
                            "& .MuiMenuItem-root": {
                              fontSize: "14px",
                              padding: "2px 5px", // Adjust the padding here to reduce space between options
                            },
                            minWidth: "0 !important",
                            width: "450px !important", // Adjust the width here
                            maxHeight: "200px",
                          },
                        },
                      }}
                    >
                      <MenuItem value="ทั้งหมด">
                        <Checkbox
                          checked={
                            pathology.length ===
                            pathologyOptions.length
                          }
                        />
                        ทั้งหมด
                      </MenuItem>
                      {pathologyOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          <Checkbox
                            checked={pathology.includes(option)}
                          />
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {/* <SelectElement
                  name="pathology"
                  size="small"
                  label="-- เลือกPathology --"
                  options={[
                    {
                      id: "",
                      label: "- โปรดระบุข้อมูล --",
                      disabled: true,
                    },
                    {
                      id: "Stomach",
                      label: "Stomach",
                    },
                    {
                      id: "Liver Preop",
                      label: "Liver Preop",
                    },
                    {
                      id: "Liver Postop",
                      label: "Liver Postop",
                    },
                    {
                      id: "Gastroscopy",
                      label: "Gastroscopy",
                    },
                  ]}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          zoom: `${100 * (100 / 85)}%`,
                          top: "-15px",
                          "& .MuiMenuItem-root": {
                            fontSize: "14px",
                          },
                          minWidth: "0 !important",
                          width: "450px !important",
                        },
                      },
                    },
                  }}
                /> */}
              </Stack>
            </Stack>
            <Box>
              <Divider sx={{ my: 2 }} />
              <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
                <Button
                  onClick={handleCancelDialog}
                  style={{ backgroundColor: "#7A8688", color: "white" }}
                >
                  ยกเลิก
                </Button>
                <Button
                  type="submit"
                  style={{ backgroundColor: "#098484", color: "white" }}
                >
                  นำไปใช้
                </Button>
              </Stack>
            </Box>
          </FormContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};

const fieldOrder: (keyof FilterItem)[] = [
  "name",
  "minDate",
  "sex",
  // "age",
  "minAge",
  "minHeight",
  "minWeightFirstVisitValue",
  "minWeightCurrentValue",
  "minBmiFirstVisit",
  "minBmiCurrent",
  "comorbidities",
  "surgeryType",
  // "surgeryNo",
  "surgeryCount",
  "pwl",
  "ewl",
  "twl",
  // "minPwl",
  "ultrasound",
  "endoscopy",
  "fibroScan",
  "unComorbidities",
  "complication",
  "followUp",
  "pathology",
];

const fieldLabels: Record<keyof FilterItem, string> = {
  name: "ชื่อ-นามสกุล",
  minDate: "ช่วงที่ค้นหาข้อมูล",
  maxDate: "ช่วงที่ค้นหาข้อมูล",
  sex: "เพศ",
  // age: "อายุ",
  minAge: "อายุ",
  maxAge: "อายุ",
  minWeightFirstVisitValue: "น้ำหนัก First Visit ( kg.)",
  maxWeightFirstVisitValue: "น้ำหนัก First Visit ( kg.)",
  minWeightCurrentValue: "น้ำหนักปัจจุบัน ( kg.)",
  maxWeightCurrentValue: "น้ำหนักปัจจุบัน ( kg.)",
  minHeight: "ความสูง (cm.)",
  maxHeight: "ความสูง (cm.)",
  minBmiFirstVisit: "BMI First Visit",
  maxBmiFirstVisit: "BMI First Visit",
  minBmiCurrent: "BMI ปัจจุบัน",
  maxBmiCurrent: "BMI ปัจจุบัน",
  comorbidities: "โรคประจำตัวและภาวะแทรกซ้อน (First Visit)",
  surgeryType: "ชนิดการผ่าตัด",
  // surgeryNo: "ครั้งที่ผ่าตัด",
  surgeryCount: "จำนวนครั้งที่ผ่าตัด",
  pwl: "Preop Weight Loss (%)",
  ewl: "EWL (%)",
  twl: "TWL (%)",
  // minPwl: "Preop Weight Loss (%)",
  // maxPwl: "Preop Weight Loss (%)",
  ultrasound: "Ultrasound",
  endoscopy: "Endoscopy",
  fibroScan: "FibroScan",
  unComorbidities: "ผลของโรคประจำตัวและภาวะแทรกซ้อน",
  complication: "Early Complication",
  followUp: "ระยะเวลา Follow Up",
  pathology: "Pathology",
};
const reportList = [
  {
    id: "RAW01",
    label: "RAW01: รายงานข้อมูล Demographic",
  },
  {
    id: "RAW02",
    label: "RAW02: รายงานข้อมูล Comorbidities & Complications",
  },
  {
    id: "RAW03",
    label: "RAW03: รายงานข้อมูล Body Composition & Lab",
  },
  {
    id: "RAW04",
    label: "RAW04: รายงานข้อมูล Gynecologic",
  },
  {
    id: "RAW05",
    label: "RAW05: รายงานข้อมูล Investigation",
  },
  {
    id: "RAW06",
    label: "RAW06: รายงานข้อมูล Pathology",
  },
  {
    id: "RAW07",
    label: "RAW07: รายงานข้อมูล Med And Nutritional",
  },
  {
    id: "RAW08",
    label: "RAW08: รายงานข้อมูล Bariatric Surgery",
  },
  {
    id: "RAW09",
    label: "RAW09: รายงานข้อมูล Late complication",
  },
  {
    id: "RAW10",
    label: "RAW10: รายงานข้อมูล End of study",
  },
  // {
  //   id: "RPT11",
  //   label: "RPT11: รายงานข้อมูล Late complication",
  // },
  // {
  //   id: "RPT12",
  //   label: "RPT12: รายงานข้อมูล End of study",
  // },
];
