import { calculateAge } from "./utils/calculateAge";
import { calculateBmi } from "./utils/calculateBmi";
import { convertThaiDateToGregorian } from "./utils/date";

export const foods = [
  {
    name: "อกไก่ 100 กรัม",
    description: "Chunky paneer with crisp capsicum and spicy red pepper",

    calories: 172,
  },
  {
    name: "เนื้อปลากะพงต้ม 100 กรัม",
    description: "Chunky paneer with crisp capsicum and spicy red pepper",

    calories: 124,
  },
  {
    name: "กุ้งต้ม 100 กรัม",
    description: "Chunky paneer with crisp capsicum and spicy red pepper",

    calories: 99,
  },
  {
    name: "ไข่ต้ม 1 ฟอง",
    description: "Chunky paneer with crisp capsicum and spicy red pepper",

    calories: 80,
  },
  {
    name: "ไข่เป็ดต้ม",
    description: "Chunky paneer with crisp capsicum and spicy red pepper",

    calories: 130,
  },
  {
    name: "ไข่ตุ๋น 1 ถ้วยเล็ก",
    description: "Chunky paneer with crisp capsicum and spicy red pepper",

    calories: 130,
  },
  {
    name: "ไข่คน 1 ถ้วยเล็ก",
    description: "Chunky paneer with crisp capsicum and spicy red pepper",

    calories: 149,
  },
  {
    name: "ไข่นกกระทาต้ม 1 ฟอง",
    description: "Antioxidant-rich berries",

    calories: 19,
  },
];
export const activityMET = [
  {
    name: "running",
    nameTh: "วิ่ง",
    met: 8,
  },
  {
    name: "yoga",
    nameTh: "โยคะ",
    met: 2.5,
  },
  {
    name: "swimming",
    nameTh: "ว่ายน้ำ",
    met: 7,
  },
  {
    name: "cardio",
    nameTh: "คาดิโอ",
    met: 6.5,
  },
  {
    name: "football",
    nameTh: "ฟุตบอล",
    met: 7,
  },
  {
    name: "tennis",
    nameTh: "เทนนิส",
    met: 8,
  },
  {
    name: "hiking",
    nameTh: "ปีนเขา",
    met: 8,
  },
  {
    name: "biking",
    nameTh: "จักรยาน",
    met: 8,
  },
  {
    name: "kayaking",
    nameTh: "พายเรือ",
    met: 5,
  },
  {
    name: "bowling",
    nameTh: "โบว์ลิ่ง",
    met: 3,
  },
  {
    name: "archery",
    nameTh: "ธนู",
    met: 3.5,
  },
  {
    name: "snooker",
    nameTh: "สนุกเกอร์",
    met: 3,
  },
  {
    name: "badminton",
    nameTh: "แบดมินตัน",
    met: 4.5,
  },
  {
    name: "golf",
    nameTh: "กอล์ฟ",
    met: 4.5,
  },
  {
    name: "tabletennis",
    nameTh: "ปิงปอง",
    met: 4,
  },
];
export const times = [
  {
    time: 15,
    label: "15 นาที",
  },
  {
    time: 30,
    label: "30 นาที",
  },
  {
    time: 60,
    label: "1 ชั่วโมง",
  },
  {
    time: 90,
    label: "1.5 ชั่วโมง+",
  },
];
// export const protectionMethodList = [
//   "ห่วงอนามัยทองแดง",
//   "ห่วงอนามัยฮอร์โมน",
//   "ยาเม็ดคุมกำเนิด",
//   "ยาฝังคุมกำเนิด",
//   "ถุงยาง",
//   "แผ่นแปะ",
//   "ทำหมันหญิง/ชาย",
//   "อื่นๆ",
// ];
export const healthBenefitList = [
  "จ่ายค่ารักษาด้วยตนเอง",
  "ประกันสุขภาพเอกชน",
  "เบิกจ่ายตรง",
  "สิทธิบัตรทอง/30 บาท",
  "สิทธิข้าราชการ",
  "สิทธิประกันสังคม",
  "เงินสด",
  "อื่นๆ",
];
export const chronicDiseaseList = [
  "โรคความดันโลหิตสูง",
  "โรคเบาหวาน",
  "โรคไขมันในเลือดสูง",
  "ภาวะไขมันสะสมในตับ",
  "โรคหลอดเลือดหัวใจ",
  "โรคหลอดเลือดสมอง",
  "โรคนิ่วในถุงน้ำดี",
  "โรคถุงน้ำผิดปกติที่รังไข่",
  "โรคข้อเข่าเสื่อม",
  "โรคกระดูกสันหลัง",
  "โรคกรดไหลย้อน",
  "โรคทางจิตเวช",
  "โรคมะเร็ง",
  "ภาวะทางเดินหายใจอุดกั้นขณะนอนหลับ",
  "โรคอื่นๆ",
];
export const rpt01SampleData = [
  {
    order: 1,
    subjectID: "CMU0001",
    hn: "HN001",
    name: "สมชาย ใจดี",
    gender: "ชาย",
    birthDate: "20/11/2530",
    age: calculateAge(convertThaiDateToGregorian("20/11/2530")),
    height: 170,
    weight: 122,
    bmi: calculateBmi(122, 170),
  },
  {
    order: 2,
    subjectID: "CMU0002",
    hn: "HN002",
    name: "นางสาว สวยงาม",
    gender: "หญิง",
    birthDate: "03/06/2534",
    age: calculateAge(convertThaiDateToGregorian("03/06/2534")),
    height: 176,
    weight: 134,
    bmi: calculateBmi(134, 176),
  },
  {
    order: 3,
    subjectID: "CMU0003",
    hn: "HN003",
    name: "นาย อ่อนน้อม",
    gender: "ชาย",
    birthDate: "29/05/2540",
    age: calculateAge(convertThaiDateToGregorian("29/05/2540")),
    height: 167,
    weight: 123,
    bmi: calculateBmi(123, 167),
  },
  {
    order: 4,
    subjectID: "CMU0004",
    hn: "HN004",
    name: "นาง รักดี",
    gender: "หญิง",
    birthDate: "31/01/2517",
    age: calculateAge(convertThaiDateToGregorian("31/01/2517")),
    height: 163,
    weight: 133,
    bmi: calculateBmi(133, 163),
  },
  {
    order: 5,
    subjectID: "CMU0005",
    hn: "HN005",
    name: "นาย พูดเพราะ",
    gender: "ชาย",
    birthDate: "05/04/2541",
    age: calculateAge(convertThaiDateToGregorian("05/04/2541")),
    height: 164,
    weight: 120,
    bmi: calculateBmi(120, 164),
  },
  {
    order: 6,
    subjectID: "CMU0001",
    hn: "HN001",
    name: "สมชาย ใจดี",
    gender: "ชาย",
    birthDate: "20/11/2530",
    age: calculateAge(convertThaiDateToGregorian("20/11/2530")),
    height: 170,
    weight: 122,
    bmi: calculateBmi(122, 170),
  },
  {
    order: 7,
    subjectID: "CMU0002",
    hn: "HN002",
    name: "นางสาว สวยงาม",
    gender: "หญิง",
    birthDate: "03/06/2534",
    age: calculateAge(convertThaiDateToGregorian("03/06/2534")),
    height: 176,
    weight: 134,
    bmi: calculateBmi(134, 176),
  },
  {
    order: 8,
    subjectID: "CMU0003",
    hn: "HN003",
    name: "นาย อ่อนน้อม",
    gender: "ชาย",
    birthDate: "29/05/2540",
    age: calculateAge(convertThaiDateToGregorian("29/05/2540")),
    height: 167,
    weight: 123,
    bmi: calculateBmi(123, 167),
  },
  {
    order: 9,
    subjectID: "CMU0004",
    hn: "HN004",
    name: "นาง รักดี",
    gender: "หญิง",
    birthDate: "31/01/2517",
    age: calculateAge(convertThaiDateToGregorian("31/01/2517")),
    height: 163,
    weight: 133,
    bmi: calculateBmi(133, 163),
  },
  {
    order: 10,
    subjectID: "CMU0005",
    hn: "HN005",
    name: "นาย พูดเพราะ",
    gender: "ชาย",
    birthDate: "05/04/2541",
    age: calculateAge(convertThaiDateToGregorian("05/04/2541")),
    height: 164,
    weight: 120,
    bmi: calculateBmi(120, 164),
  },
  {
    order: 11,
    subjectID: "CMU0001",
    hn: "HN001",
    name: "สมชาย ใจดี",
    gender: "ชาย",
    birthDate: "20/11/2530",
    age: calculateAge(convertThaiDateToGregorian("20/11/2530")),
    height: 170,
    weight: 122,
    bmi: calculateBmi(122, 170),
  },
  {
    order: 12,
    subjectID: "CMU0002",
    hn: "HN002",
    name: "นางสาว สวยงาม",
    gender: "หญิง",
    birthDate: "03/06/2534",
    age: calculateAge(convertThaiDateToGregorian("03/06/2534")),
    height: 176,
    weight: 134,
    bmi: calculateBmi(134, 176),
  },
  {
    order: 13,
    subjectID: "CMU0003",
    hn: "HN003",
    name: "นาย อ่อนน้อม",
    gender: "ชาย",
    birthDate: "29/05/2540",
    age: calculateAge(convertThaiDateToGregorian("29/05/2540")),
    height: 167,
    weight: 123,
    bmi: calculateBmi(123, 167),
  },
  {
    order: 14,
    subjectID: "CMU0004",
    hn: "HN004",
    name: "นาง รักดี",
    gender: "หญิง",
    birthDate: "31/01/2517",
    age: calculateAge(convertThaiDateToGregorian("31/01/2517")),
    height: 163,
    weight: 133,
    bmi: calculateBmi(133, 163),
  },
  {
    order: 15,
    subjectID: "CMU0005",
    hn: "HN005",
    name: "นาย พูดเพราะ",
    gender: "ชาย",
    birthDate: "05/04/2541",
    age: calculateAge(convertThaiDateToGregorian("05/04/2541")),
    height: 164,
    weight: 120,
    bmi: calculateBmi(120, 164),
  },
];
export const rpt02SampleData = [
  {
    order: 1,
    hn: "HN001",
    name: "สมชาย ใจดี",
    gender: "ชาย",
    birthDate: "20/11/2530",
    age: calculateAge(convertThaiDateToGregorian("20/11/2530")),
    height: 170,
    weight: 122,
    bmi: calculateBmi(122, 170),
    previousฺariatricSurgery: false,
    referHn: false,
    cancerScreen: true,
  },
  {
    order: 2,
    hn: "HN002",
    name: "นางสาว สวยงาม",
    gender: "หญิง",
    birthDate: "03/06/2534",
    age: calculateAge(convertThaiDateToGregorian("03/06/2534")),
    height: 176,
    weight: 134,
    bmi: calculateBmi(134, 176),
    previousฺariatricSurgery: false,
    referHn: false,
    cancerScreen: true,
  },
  {
    order: 3,
    hn: "HN003",
    name: "นาย อ่อนน้อม",
    gender: "ชาย",
    birthDate: "29/05/2540",
    age: calculateAge(convertThaiDateToGregorian("29/05/2540")),
    height: 167,
    weight: 123,
    bmi: calculateBmi(123, 167),
    previousฺariatricSurgery: false,
    referHn: false,
    cancerScreen: true,
  },
  {
    order: 4,
    hn: "HN004",
    name: "นาง รักดี",
    gender: "หญิง",
    birthDate: "31/01/2517",
    age: calculateAge(convertThaiDateToGregorian("31/01/2517")),
    height: 163,
    weight: 133,
    bmi: calculateBmi(133, 163),
    previousฺariatricSurgery: false,
    referHn: false,
    cancerScreen: true,
  },
  {
    order: 5,
    hn: "HN005",
    name: "นาย พูดเพราะ",
    gender: "ชาย",
    birthDate: "05/04/2541",
    age: calculateAge(convertThaiDateToGregorian("05/04/2541")),
    height: 164,
    weight: 120,
    bmi: calculateBmi(120, 164),
    previousฺariatricSurgery: false,
    referHn: false,
    cancerScreen: true,
  },
];
export const rpt04SampleData = [
  {
    order: 1,
    hn: "HN001",
    name: "สมชาย ใจดี",
    vistDate: "20/11/2566",
    sbp: 120,
    dbp: 80,
    weight: 122,
    idealWeight: 70,
    twl: 30,
    ebmil: 10,
    smm: 50,
    bfm: 40,
    pbf: 25,
    fbs: 90,
    hba1c: 5.5,
    cholesterol: 180,
  },
  {
    order: 2,
    hn: "HN002",
    name: "นางสาว สวยงาม",
    vistDate: "13/02/2564",
    sbp: 115,
    dbp: 75,
    weight: 134,
    idealWeight: 72,
    twl: 28,
    ebmil: 9,
    smm: 55,
    bfm: 45,
    pbf: 30,
    fbs: 85,
    hba1c: 5.4,
    cholesterol: 175,
  },
  {
    order: 3,
    hn: "HN003",
    name: "นาย อ่อนน้อม",
    vistDate: "21/01/2567",
    sbp: 130,
    dbp: 85,
    weight: 123,
    idealWeight: 68,
    twl: 35,
    ebmil: 12,
    smm: 52,
    bfm: 38,
    pbf: 22,
    fbs: 92,
    hba1c: 5.6,
    cholesterol: 190,
  },
  {
    order: 4,
    hn: "HN004",
    name: "นาง รักดี",
    vistDate: "27/11/2560",
    sbp: 125,
    dbp: 78,
    weight: 133,
    idealWeight: 65,
    twl: 32,
    ebmil: 11,
    smm: 48,
    bfm: 35,
    pbf: 28,
    fbs: 88,
    hba1c: 5.3,
    cholesterol: 160,
  },
  {
    order: 5,
    hn: "HN005",
    name: "นาย พูดเพราะ",
    vistDate: "27/11/2560",
    sbp: 128,
    dbp: 82,
    weight: 120,
    idealWeight: 66,
    twl: 25,
    ebmil: 8,
    smm: 53,
    bfm: 36,
    pbf: 24,
    fbs: 87,
    hba1c: 5.2,
    cholesterol: 170,
  },
];
export const rpt06SampleData = [
  {
    order: 1,
    hn: "HN001",
    name: "สมชาย ใจดี",
    vistDate: "20/11/2566",
    ht: true,
    dm: false,
    dlp: true,
    nafld: false,
    cad: true,
    cva: false,
    gallstones: true,
    pcos: false,
    kneeOsteoarthritis: true,
    spondylosis: false,
    gerd: true,
    psych: false,
    caco: false,
    osa: true,
    cpap: true,
    other: "",
  },
  {
    order: 2,
    hn: "HN002",
    name: "นางสาว สวยงาม",
    vistDate: "13/02/2564",
    ht: false,
    dm: true,
    dlp: false,
    nafld: false,
    cad: false,
    cva: false,
    gallstones: false,
    pcos: true,
    kneeOsteoarthritis: false,
    spondylosis: true,
    gerd: false,
    psych: false,
    caco: false,
    osa: false,
    cpap: false,
    other: "-",
  },
  {
    order: 3,
    hn: "HN003",
    name: "นาย อ่อนน้อม",
    vistDate: "21/01/2567",
    ht: true,
    dm: true,
    dlp: true,
    nafld: true,
    cad: false,
    cva: false,
    gallstones: false,
    pcos: false,
    kneeOsteoarthritis: true,
    spondylosis: true,
    gerd: false,
    psych: false,
    caco: true,
    osa: false,
    cpap: true,
    other: "",
  },
  {
    order: 4,
    hn: "HN004",
    name: "นาง รักดี",
    vistDate: "27/11/2560",
    ht: false,
    dm: false,
    dlp: false,
    nafld: false,
    cad: true,
    cva: false,
    gallstones: true,
    pcos: false,
    kneeOsteoarthritis: false,
    spondylosis: false,
    gerd: true,
    psych: false,
    caco: false,
    osa: false,
    cpap: false,
    other: "-",
  },
  {
    order: 5,
    hn: "HN005",
    name: "นาย พูดเพราะ",
    vistDate: "27/11/2560",
    ht: true,
    dm: false,
    dlp: true,
    nafld: false,
    cad: false,
    cva: false,
    gallstones: false,
    pcos: true,
    kneeOsteoarthritis: true,
    spondylosis: false,
    gerd: false,
    psych: true,
    caco: false,
    osa: true,
    cpap: false,
    other: "",
  },
];
export const rpt07SampleData = [
  {
    order: 1,
    hn: "HN001",
    name: "สมชาย ใจดี",
    vistDate: "20/11/2566",
    pathology: true,
    other: "",
  },
  {
    order: 2,
    hn: "HN002",
    name: "นางสาว สวยงาม",
    vistDate: "13/02/2564",
    pathology: false,
    other: "-",
  },
  {
    order: 3,
    hn: "HN003",
    name: "นาย อ่อนน้อม",
    vistDate: "21/01/2567",
    pathology: true,
    other: "sample",
  },
  {
    order: 4,
    hn: "HN004",
    name: "นาง รักดี",
    vistDate: "27/11/2560",
    pathology: false,
    other: "-",
  },
  {
    order: 5,
    hn: "HN005",
    name: "นาย พูดเพราะ",
    vistDate: "27/11/2560",
    pathology: true,
    other: "",
  },
];
export const rpt08SampleData = [
  {
    order: 1,
    hn: "HN001",
    name: "สมชาย ใจดี",
    vistDate: "20/11/2566",
    sexuallyActive: true,
    frequencyOfSexualIntercourse: "< 1 ครั้งต่อเดือน",
    maleCondom: true,
    combinedPills: false,
    singlePills: true,
    dmpa: false,
    implant: false,
    copper: true,
    levo: false,
    withdrawal: true,
    awareness: false,
    other: false,
    pregnancyIntention: true,
    pregnancyPlan: 2,
    regularMenstruationPastThreeMonth: true,
  },
  {
    order: 2,
    hn: "HN002",
    name: "นางสาว สวยงาม",
    vistDate: "13/02/2564",
    sexuallyActive: false,
    frequencyOfSexualIntercourse: "1 ครั้งต่อสัปดาห์",
    maleCondom: false,
    combinedPills: true,
    singlePills: false,
    dmpa: true,
    implant: false,
    copper: true,
    levo: false,
    withdrawal: false,
    awareness: true,
    other: false,
    pregnancyIntention: false,
    pregnancyPlan: 0,
    regularMenstruationPastThreeMonth: true,
  },
  {
    order: 3,
    hn: "HN003",
    name: "นาย อ่อนน้อม",
    vistDate: "21/01/2567",
    sexuallyActive: true,
    frequencyOfSexualIntercourse: "1 ครั้งต่อสัปดาห์",
    maleCondom: true,
    combinedPills: false,
    singlePills: true,
    dmpa: false,
    implant: true,
    copper: false,
    levo: true,
    withdrawal: false,
    awareness: true,
    other: true,
    pregnancyIntention: true,
    pregnancyPlan: 1,
    regularMenstruationPastThreeMonth: false,
  },
  {
    order: 4,
    hn: "HN004",
    name: "นาง รักดี",
    vistDate: "27/11/2560",
    sexuallyActive: false,
    frequencyOfSexualIntercourse: "1 ครั้งต่อสัปดาห์",
    maleCondom: false,
    combinedPills: true,
    singlePills: false,
    dmpa: true,
    implant: false,
    copper: true,
    levo: false,
    withdrawal: true,
    awareness: false,
    other: false,
    pregnancyIntention: false,
    pregnancyPlan: 0,
    regularMenstruationPastThreeMonth: true,
  },
  {
    order: 5,
    hn: "HN005",
    name: "นาย พูดเพราะ",
    vistDate: "27/11/2560",
    sexuallyActive: true,
    frequencyOfSexualIntercourse: ">= 2 ครั้งต่อสัปดาห์",
    maleCondom: true,
    combinedPills: false,
    singlePills: false,
    dmpa: false,
    implant: true,
    copper: true,
    levo: false,
    withdrawal: true,
    awareness: false,
    other: true,
    pregnancyIntention: true,
    pregnancyPlan: 3,
    regularMenstruationPastThreeMonth: true,
  },
];
export const rpt09SampleData = [
  {
    order: 1,
    hn: "HN001",
    name: "สมชาย ใจดี",
    vistDate: "20/11/2566",
    fattyLiver: true,
    gallstone: false,
    cirrhosis: true,
    ascites: false,
    hiatalHernia: true,
    refluxEsophagitis: false,
    gastritis: true,
    gastricDuodenalUlcer: false,
    hPyloriInfection: true,
    fibroScan: false,
    other: "-",
  },
  {
    order: 2,
    hn: "HN002",
    name: "นางสาว สวยงาม",
    vistDate: "13/02/2564",
    fattyLiver: false,
    gallstone: true,
    cirrhosis: false,
    ascites: false,
    hiatalHernia: true,
    refluxEsophagitis: true,
    gastritis: false,
    gastricDuodenalUlcer: true,
    hPyloriInfection: false,
    fibroScan: true,
    other: "-",
  },
  {
    order: 3,
    hn: "HN003",
    name: "นาย อ่อนน้อม",
    vistDate: "21/01/2567",
    fattyLiver: true,
    gallstone: false,
    cirrhosis: false,
    ascites: true,
    hiatalHernia: false,
    refluxEsophagitis: false,
    gastritis: true,
    gastricDuodenalUlcer: false,
    hPyloriInfection: true,
    fibroScan: true,
    other: "-",
  },
  {
    order: 4,
    hn: "HN004",
    name: "นาง รักดี",
    vistDate: "27/11/2560",
    fattyLiver: false,
    gallstone: false,
    cirrhosis: true,
    ascites: true,
    hiatalHernia: false,
    refluxEsophagitis: true,
    gastritis: false,
    gastricDuodenalUlcer: false,
    hPyloriInfection: true,
    fibroScan: false,
    other: "-",
  },
  {
    order: 5,
    hn: "HN005",
    name: "นาย พูดเพราะ",
    vistDate: "27/11/2560",
    fattyLiver: true,
    gallstone: true,
    cirrhosis: false,
    ascites: false,
    hiatalHernia: true,
    refluxEsophagitis: false,
    gastritis: true,
    gastricDuodenalUlcer: false,
    hPyloriInfection: false,
    fibroScan: true,
    other: "-",
  },
];
export const rpt10SampleData = [
  {
    order: 1,
    hn: "HN001",
    name: "สมชาย ใจดี",
    vistDate: "20/11/2566",
    medicationUse: true,
    dmMed: true,
    glp1Ra: false,
    insulin: false,
    other: "-",
    dlpMed: false,
    htMed: true,
    supplementUse: true,
    calcium: true,
    vitaminD: false,
    iron: true,
    vitaminB12: false,
  },
  {
    order: 2,
    hn: "HN002",
    name: "นางสาว สวยงาม",
    vistDate: "13/02/2564",
    medicationUse: true,
    dmMed: false,
    glp1Ra: true,
    insulin: false,
    other: "-",
    dlpMed: true,
    htMed: true,
    supplementUse: true,
    calcium: false,
    vitaminD: true,
    iron: false,
    vitaminB12: true,
  },
  {
    order: 3,
    hn: "HN003",
    name: "นาย อ่อนน้อม",
    vistDate: "21/01/2567",
    medicationUse: false,
    dmMed: false,
    glp1Ra: false,
    insulin: true,
    other: "Insulin",
    dlpMed: false,
    htMed: true,
    supplementUse: false,
    calcium: false,
    vitaminD: false,
    iron: false,
    vitaminB12: true,
  },
  {
    order: 4,
    hn: "HN004",
    name: "นาง รักดี",
    vistDate: "27/11/2560",
    medicationUse: true,
    dmMed: true,
    glp1Ra: false,
    insulin: false,
    other: "-",
    dlpMed: false,
    htMed: true,
    supplementUse: false,
    calcium: false,
    vitaminD: false,
    iron: false,
    vitaminB12: false,
  },
  {
    order: 5,
    hn: "HN005",
    name: "นาย พูดเพราะ",
    vistDate: "27/11/2560",
    medicationUse: false,
    dmMed: false,
    glp1Ra: false,
    insulin: false,
    other: "None",
    dlpMed: true,
    htMed: false,
    supplementUse: true,
    calcium: true,
    vitaminD: true,
    iron: true,
    vitaminB12: false,
  },
];
export const rpt11SampleData = [
  {
    order: 1,
    hn: "HN001",
    name: "สมชาย ใจดี",
    vistDate: "20/11/2566",
    lateComplication: true,
    gerd: true,
    gerdType: "-",
    hernia: false,
    herniaType: "-",
    marginalUlcer: true,
    anastomosisStricture: false,
    dumpingSyndrome: true,
    vte: false,
    cardiovascularEvents: false,
    psychiatricProblems: true,
    osteoporosis: false,
    other: "-",
  },
  {
    order: 2,
    hn: "HN002",
    name: "นางสาว สวยงาม",
    vistDate: "13/02/2564",
    lateComplication: false,
    gerd: false,
    gerdType: "-",
    hernia: true,
    herniaType: "-",
    marginalUlcer: false,
    anastomosisStricture: true,
    dumpingSyndrome: false,
    vte: true,
    cardiovascularEvents: false,
    psychiatricProblems: false,
    osteoporosis: true,
    other: "-",
  },
  {
    order: 3,
    hn: "HN003",
    name: "นาย อ่อนน้อม",
    vistDate: "21/01/2567",
    lateComplication: true,
    gerd: true,
    gerdType: "-",
    hernia: false,
    herniaType: "-",
    marginalUlcer: false,
    anastomosisStricture: false,
    dumpingSyndrome: true,
    vte: false,
    cardiovascularEvents: true,
    psychiatricProblems: true,
    osteoporosis: false,
    other: "-",
  },
  {
    order: 4,
    hn: "HN004",
    name: "นาง รักดี",
    vistDate: "27/11/2560",
    lateComplication: false,
    gerd: false,
    gerdType: "-",
    hernia: true,
    herniaType: "-",
    marginalUlcer: true,
    anastomosisStricture: true,
    dumpingSyndrome: false,
    vte: false,
    cardiovascularEvents: false,
    psychiatricProblems: false,
    osteoporosis: true,
    other: "-",
  },
  {
    order: 5,
    hn: "HN005",
    name: "นาย พูดเพราะ",
    vistDate: "27/11/2560",
    lateComplication: true,
    gerd: true,
    gerdType: "-",
    hernia: true,
    herniaType: "-",
    marginalUlcer: false,
    anastomosisStricture: false,
    dumpingSyndrome: true,
    vte: true,
    cardiovascularEvents: true,
    psychiatricProblems: false,
    osteoporosis: true,
    other: "-",
  },
];
export const rpt12SampleData = [
  {
    order: 1,
    hn: "HN001",
    name: "สมชาย ใจดี",
    vistDate: "20/11/2566",
    daySinceLastVisit: 30,
    endOfStudy: true,
    endOfStudyType: "Loss to f/u",
    date: "20/11/2566",
  },
  {
    order: 2,
    hn: "HN002",
    name: "นางสาว สวยงาม",
    vistDate: "13/02/2564",
    daySinceLastVisit: 500,
    endOfStudy: true,
    endOfStudyType: "Loss to f/u",
    date: "13/02/2564",
  },
  {
    order: 3,
    hn: "HN003",
    name: "นาย อ่อนน้อม",
    vistDate: "21/01/2567",
    daySinceLastVisit: 10,
    endOfStudy: false,
    endOfStudyType: "-",
    date: "-",
  },
  {
    order: 4,
    hn: "HN004",
    name: "นาง รักดี",
    vistDate: "27/11/2560",
    daySinceLastVisit: 365,
    endOfStudy: true,
    endOfStudyType: "-",
    date: "-",
  },
  {
    order: 5,
    hn: "HN005",
    name: "นาย พูดเพราะ",
    vistDate: "01/05/2566",
    daySinceLastVisit: 200,
    endOfStudy: true,
    endOfStudyType: "Dead",
    date: "27/11/2560",
  },
];

export const reportValues = [
  {
    reportType: "RAW01",
    reportTitle: "รายงานข้อมูล Demographic",
    reportDescription: "ข้อมูลทั่วไป",
    columns: [
      { name: "ลำดับ", align: "center", key: "order" },
      { name: "HN", align: "left", key: "hn" },
      { name: "ชื่อ-นามสกุล", align: "left", key: "name" },
      { name: "Visit Date", align: "left", key: "visitDate" },
      { name: "Sex", align: "center", key: "sex" },
      { name: "วันเกิด", align: "left", key: "dateOfBirth" },
      { name: "อายุ", align: "left", key: "age" },
      { name: "Previous Bariatric Surgery ", align: "center", key: "surgeryBefore" },
      { name: "Refer to HN", align: "center", key: "referToHn" },
      { name: "ส่วนสูง (cm)", align: "center", key: "height" },
      { name: "น้ำหนัก (kg)", align: "center", key: "weight" },
      { name: "BMI", align: "center", key: "bmi" },
      { name: "Cervical cancer screening", align: "center", key: "cervicalCancerScreening" },
    ],
    data: rpt01SampleData,
  },
  {
    reportType: "RAW02",
    reportTitle: "รายงานข้อมูล Comorbidities & Complications",
    reportDescription: "ประวัติโรคร่วมและโรคแทรกซ้อน",
    columns: [
      { name: "ลำดับ", align: "center", key: "order" },
      { name: "HN", align: "left", key: "hn" },
      { name: "ชื่อ-นามสกุล", align: "left", key: "name" },
      {
        name: "วันที่ Visit",
        align: "left",
        key: "visitDate",
      },
      { name: "โรคความดันโลหิตสูง (HT)", align: "center", key: "ht" },
      { name: "โรคเบาหวาน (DM)", align: "center", key: "dm" },
      { name: "โรคไขมันในเลือดสูง (DLP)", align: "center", key: "dlp" },
      {
        name: "ภาวะไขมันสะสมในตับ (NAFLD/NASH)",
        align: "center",
        key: "nafld",
      },
      { name: "โรคหลอดเลือดหัวใจ (CAD)", align: "center", key: "cad" },
      { name: "โรคหลอดเลือดสมอง (CVA)", align: "center", key: "cva" },
      {
        name: "โรคนิ่วในถุงน้ำดี (Gallstones)",
        align: "center",
        key: "gallstones",
      },
      {
        name: "โรคถุงน้ำผิดปกติที่รังไข่ (PCOS)",
        align: "center",
        key: "pcos",
      },
      { name: "โรคข้อเข่าเสื่อม", align: "center", key: "kneeOsteoarthritis" },
      { name: "โรคกระดูกสันหลัง", align: "center", key: "spondylosis" },
      { name: "โรคกรดไหลย้อน (GERD)", align: "center", key: "gerd" },
      { name: "โรคทางจิตเวช", align: "center", key: "psych" },
      { name: "ประวัติโรคมะเร็ง", align: "center", key: "caco" },
      {
        name: "ภาวะทางเดินหายใจอุดกั้นขณะนอนหลับ (OSA)",
        align: "center",
        key: "osa",
      },
      { name: "CPAP use", align: "center", key: "cpap" },
    ],
    data: rpt06SampleData,
  },
  {
    reportType: "RAW03",
    reportTitle: "รายงานข้อมูล Body Composition & Lab",
    reportDescription: "Body composition & Laboratory",
    columns: [
      { name: "ลำดับ", align: "center", key: "order" },
      { name: "HN", align: "left", key: "hn" },
      { name: "ชื่อ-นามสกุล", align: "left", key: "name" },
      {
        name: "วันที่ Visit",
        align: "left",
        key: "visitDate",
      },
      { name: "SBP", align: "center", key: "sbp" },
      { name: "DBP", align: "center", key: "dbp" },
      { name: "Weight", align: "center", key: "weight" },
      { name: "BMI", align: "center", key: "bmi" },
      { name: "Ideal weight", align: "center", key: "idealWeight" },
      { name: "Percent of excess weight loss (%EWL)", align: "center", key: "excessWeightLoss" },
      { name: "Percent of total weight loss (%TWL)", align: "center", key: "totalWeightLoss" },
      { name: "Percent excess BMI loss (%EBMIL)", align: "center", key: "excessBmiLoss" },
      { name: "SMM", align: "center", key: "smm" },
      { name: "BFM", align: "center", key: "bfm" },
      { name: "PBF", align: "center", key: "pbf" },
      { name: "Hb", align: "center", key: "hb" },
      { name: "Hct", align: "center", key: "hct" },
      { name: "MVC", align: "center", key: "mcv" },
      { name: "Platelet", align: "center", key: "plt" },
      { name: "FBS", align: "center", key: "fbs" },
      { name: "HbA1C", align: "center", key: "hba1c" },
      { name: "Cholesterol", align: "center", key: "chol" },
      { name: "HDL", align: "center", key: "hdl" },
      { name: "Triglyceride", align: "center", key: "tg" },
      { name: "LDL-C", align: "center", key: "ldlc" },
      { name: "Creatinine", align: "center", key: "cr" },
      { name: "Albumin", align: "center", key: "alb" },
      { name: "SGOT", align: "center", key: "sgot" },
      { name: "SGPT", align: "center", key: "sgpt" },
      { name: "ALP", align: "center", key: "alp" },
      { name: "Vitamin D", align: "center", key: "vitaminD" },
      { name: "Vitamin B12", align: "center", key: "vitaminB12" },
      { name: "Folate", align: "center", key: "folate" },
      { name: "Ferritin", align: "center", key: "ferritin" },
      { name: "Serum Iron", align: "center", key: "serumIron" },
      { name: "TIBC", align: "center", key: "tib" },
    ],
    data: rpt04SampleData,
  },
  {
    reportType: "RAW04",
    reportTitle: "รายงานข้อมูล Gynecologic",
    reportDescription: "ข้อมูลโรคทางสูติศาสตร์และนารีเวช",
    columns: [
      { name: "ลำดับ", align: "center", key: "order" },
      { name: "HN", align: "left", key: "hn" },
      { name: "ชื่อ-นามสกุล", align: "left", key: "name" },
      {
        name: "วันที่ Visit",
        align: "left",
        key: "visitDate",
      },
      { name: "Sexually active", align: "center", key: "sexuallyActive" },
      {
        name: "Frequency of sexual intercourse",
        align: "center",
        key: "frequencyOfSexualIntercourse",
      },
      {
        name: "Male condom (ถุงยางอนามัยชาย)",
        align: "center",
        key: "maleCondom",
      },
      {
        name: "Combined oral contraceptive pills (ยาเม็ดคุมกำเนิดฮอร์โมนรวม)",
        align: "center",
        key: "combinedOralContraceptivePill",
      },
      {
        name: "Combined oral contraceptive pills (ยาเม็ดคุมกำเนิดฮอร์โมนเดี่ยวหรือยาเม็ดคุมกำเนิดสำหรับให้นมบุตร)",
        align: "center",
        key: "combinedOralContraceptivePillTargeted",
      },
      { name: "DMPA (ยาฉีดคุมกำเนิด)", align: "center", key: "dmpa" },
      {
        name: "Contraceptive implant (ยาฝังคุมกำเนิด)",
        align: "center",
        key: "contraceptiveImplant",
      },
      { name: "Copper IUD (ห่วงอนามัยทองแดง)", align: "center", key: "copperIud" },
      {
        name: "Levonorgestrel IUD (ห่วงอนามัยฮอร์โมน)",
        align: "center",
        key: "levonorgestrelIud",
      },
      { name: "Withdrawal (หลั่งนอก)", align: "center", key: "withdrawal" },
      {
        name: "Fertility awareness (นับวันปลอดภัย)",
        align: "center",
        key: "fertilityAwareness",
      },
      // { name: "Other (อื่นๆ)", align: "center", key: "other" },
      // {
      //   name: "Pregnancy intention (ความต้องการมีบุตรในระยะยาว)",
      //   align: "center",
      //   key: "pregnancyIntention",
      // },
      // {
      //   name: "วางแผนมีบุตรในระยะกี่ปี",
      //   align: "center",
      //   key: "pregnancyPlan",
      // },
      // {
      //   name: "Regular menstruation in past 3 months (ประจำเดือนมาห่าง 21-35 วัน)",
      //   align: "center",
      //   key: "regularMenstruationPastThreeMonth",
      // },
    ],
    data: rpt08SampleData,
  },
  {
    reportType: "RAW05",
    reportTitle: "รายงานข้อมูล Investigation",
    reportDescription: "ข้อมูล Investigation",
    columns: [
      { name: "ลำดับ", align: "center", key: "order" },
      { name: "HN", align: "left", key: "hn" },
      { name: "ชื่อ-นามสกุล", align: "left", key: "name" },
      {
        name: "วันที่ Visit",
        align: "left",
        key: "visitDate",
      },
      { name: "Fatty Liver", align: "center", key: "fattyLiver" },
      { name: "Gallstone", align: "center", key: "gallstone" },
      { name: "Cirrhosis", align: "center", key: "cirrhosis" },
      { name: "Ascites", align: "center", key: "ascites" },
      { name: "FibroScan", align: "center", key: "fibroScan" },
      { name: "Hiatal Hernia", align: "center", key: "hiatalHernia" },
      { name: "Reflux Esophagitis", align: "center", key: "refluxEsophagitis" },
      { name: "Gastritis", align: "center", key: "gastritis" },
      { name: "Gastric/Duodenal Ulcer", align: "center", key: "gastricDuodenalUlcer" },
      { name: "H. pyroli Infection", align: "center", key: "hpyroliInfection" },
      // { name: "Other", align: "center", key: "other" },
    ],
    data: rpt09SampleData,
  },
  {
    reportType: "RAW06",
    reportTitle: "รายงานข้อมูล Pathology",
    reportDescription: "ข้อมูล Pathology",
    columns: [
      { name: "ลำดับ", align: "center", key: "order" },
      { name: "HN", align: "left", key: "hn" },
      { name: "ชื่อ-นามสกุล", align: "left", key: "name" },
      {
        name: "วันที่ Visit",
        align: "left",
        key: "visitDate",
      },
      { name: "Stomach", align: "center", key: "stomach" },
      { name: "Stomach (ระบุ)", align: "center", key: "stomachNote" },
      { name: "Liver Preop", align: "center", key: "liverPreOp" },
      { name: "Liver Preop (ระบุ)", align: "center", key: "liverPreOpNote" },
      { name: "Liver Postop", align: "center", key: "liverPostOp" },
      { name: "Liver Postop (ระบุ)", align: "center", key: "liverPostOpNote" },
      { name: "Gastroscopy", align: "center", key: "gastroscopy" },
      { name: "Gastroscopy (ระบุ)", align: "center", key: "gastroscopyNote" },
    ],
    data: rpt07SampleData,
  },
  {
    reportType: "RAW07",
    reportTitle: "รายงานข้อมูล Med And Nutritional",
    reportDescription: "ข้อมูล Med and Nutritional",
    columns: [
      { name: "ลำดับ", align: "center", key: "order" },
      { name: "HN", align: "left", key: "hn" },
      { name: "ชื่อ-นามสกุล", align: "left", key: "name" },
      {
        name: "วันที่ Visit",
        align: "left",
        key: "visitDate",
      },
      { name: "Medication Use", align: "center", key: "medicationUse" },
      { name: "DM med", align: "center", key: "dmMed" },
      { name: "GLP-1 RA", align: "center", key: "glp1Ra" },
      { name: "Insulin", align: "center", key: "insulin" },
      { name: "Other", align: "center", key: "dmOther" },
      { name: "Other (ระบุ)", align: "center", key: "dmOtherNote" },
      { name: "DLP med", align: "center", key: "dlpMed" },
      { name: "HT med", align: "center", key: "htMed" },
      { name: "Nutritional Supplement", align: "center", key: "supplementUse" },
      { name: "Calcium Supplement", align: "center", key: "calcium" },
      { name: "Vitamin D Supplement", align: "center", key: "vitaminD" },
      { name: "Iron Supplement", align: "center", key: "iron" },
      { name: "Vitamin B12 (1000 mcg/ml)", align: "center", key: "vitaminB12" },
    ],
    data: rpt10SampleData,
  },
  {
    reportType: "RAW08",
    reportTitle: "รายงานข้อมูล Bariatric Surgery",
    reportDescription: "ข้อมูล Bariatric Surgery",
    columns: [
      { name: "ลำดับ", align: "center", key: "order" },
      { name: "HN", align: "left", key: "hn" },
      { name: "ชื่อ-นามสกุล", align: "left", key: "name" },
      {
        name: "วันที่ Visit",
        align: "left",
        key: "visitDate",
      },
      { name: "วันที่ผ่าตัด", align: "center", key: "surgeryDate" },
      { name: "Op-Time (minutes)", align: "center", key: "operationTime" },
      { name: "Acute weight loss", align: "center", key: "acuteWeightLoss" },
      { name: "Acute weight loss (Type)", align: "center", key: "acuteWeightLossType" },
      { name: "ระบุจำนวนวัน", align: "center", key: "acuteWeightLossDay" },
      { name: "Pre-AWL Weight", align: "center", key: "preAwlWeight" },
      {
        name: "Post-AWL Weight",
        align: "center",
        key: "postAwlWeight",
      },
      { name: "Weight change (%)", align: "center", key: "weightChange" },
      {
        name: "Surgical Technique",
        align: "center",
        key: "surgicalTechnique",
      },
      {
        name: "ชนิดการผ่าตัด",
        align: "center",
        key: "operationType",
      },
      { name: "DJ anastomosis", align: "center", key: "djAnastomosis" },
      { name: "GJ anastomosis", align: "center", key: "gjAnastomosis" },
      { name: "JJ anastomosis", align: "center", key: "jjAnastomosis" },
      { name: "DJ anastomosis Technique", align: "center", key: "djAnastomosisTechnique" },
      { name: "GJ anastomosis Technique", align: "center", key: "gjAnastomosisTechnique" },
      { name: "JJ anastomosis Technique", align: "center", key: "jjAnastomosisTechnique" },
      { name: "Technique (ระบุข้อมูล)", align: "center", key: "techniqueDetail" },
      { name: "Bougie size", align: "center", key: "bougieSize" },
      { name: "BP limb (cm)", align: "center", key: "bpLimb" },
      { name: "Roux limb (cm)", align: "center", key: "rouxLimb" },
      { name: "EBL(mL)", align: "center", key: "ebl" },
      { name: "Subsequent Operation", align: "center", key: "subsequentOperation" },
      { name: "Subsequent Operation (ระบุ)", align: "center", key: "subsequentOperationOtherText" },
      { name: "Early Complications", align: "center", key: "earlyComplication" },
      { name: "Leakage", align: "center", key: "leakage" },
      { name: "Surgical Site Infection", align: "center", key: "surgicalSiteInfection" },
      { name: "Obstruction", align: "center", key: "obstruction" },
      { name: "Cardiovascular Events", align: "center", key: "cardiovascularEvents" },
      { name: "Bleeding", align: "center", key: "bleeding" },
      { name: "Pulmonary Complication", align: "center", key: "pulmonaryComplication" },
      { name: "VTE", align: "center", key: "vte" },
      { name: "Other", align: "center", key: "other" },
      { name: "Treatment", align: "center", key: "treatment" },
      { name: "Other (ระบุ)", align: "center", key: "earlyComplicationTypeOtherText" },
      { name: "Re-operation", align: "center", key: "reOperation" },
    ],
    data: rpt11SampleData,
  },
  {
    reportType: "RAW09",
    reportTitle: "รายงานข้อมูล Late complication",
    reportDescription: "ข้อมูล Late complication",
    columns: [
      { name: "ลำดับ", align: "center", key: "order" },
      { name: "HN", align: "left", key: "hn" },
      { name: "ชื่อ-นามสกุล", align: "left", key: "name" },
      {
        name: "วันที่ Visit",
        align: "left",
        key: "visitDate",
      },
      { name: "Late complication", align: "center", key: "lateComplication" },
      { name: "GERD", align: "center", key: "gerd" },
      { name: "GERD (ประเภท)", align: "center", key: "gerdType" },
      { name: "Hernia", align: "center", key: "hernia" },
      { name: "Hernia (ประเภท)", align: "center", key: "herniaType" },
      { name: "Marginal Ulcer", align: "center", key: "marginalUlcer" },
      {
        name: "Anastomosis stricture",
        align: "center",
        key: "anastomosisStricture",
      },
      { name: "Dumping Syndrome", align: "center", key: "dumpingSyndrome" },
      { name: "VTE", align: "center", key: "vte" },
      {
        name: "Cardiovascular events (MI, Stroke)",
        align: "center",
        key: "cardiovascularEvents",
      },
      {
        name: "Psychiatric problems",
        align: "center",
        key: "psychiatricProblems",
      },
      { name: "Osteoporosis", align: "center", key: "osteoporosis" },
      { name: "Other", align: "center", key: "other" },
      { name: "Other (ระบุ)", align: "center", key: "otherText" },
    ],
    data: rpt11SampleData,
  },
  {
    reportType: "RAW10",
    reportTitle: "รายงานข้อมูล End of study",
    reportDescription: "ข้อมูล End of study",
    columns: [
      { name: "ลำดับ", align: "center", key: "order" },
      { name: "HN", align: "left", key: "hn" },
      { name: "ชื่อ-นามสกุล", align: "left", key: "name" },
      {
        name: "วันที่ Visit",
        align: "left",
        key: "visitDate",
      },
      {
        name: "Amount of time since the latest visit",
        align: "center",
        key: "daySinceLastVisit",
      },
      { name: "End of study", align: "center", key: "endOfStudy" },
      { name: "ประเภท", align: "center", key: "endOfStudyType" },
      { name: "Date", align: "left", key: "endOfStudyDate" },
    ],
    data: rpt12SampleData,
  },
  {
    reportType: "RPT02",
    reportTitle: "รายงานภาพรวมข้อมูลผู้ป่วย",
    reportDescription: "",
    columns: [
      { name: "ลำดับ", align: "center", key: "order" },
      { name: "HN", align: "left", key: "hn" },
      { name: "ชื่อ-นามสกุล", align: "left", key: "name" },
      {
        name: "Previous Bariatric Surgery",
        align: "center",
        key: "previousฺariatricSurgery",
      },
      { name: "เพศ", align: "center", key: "gender" },
      { name: "Refer to HN", align: "center", key: "referHn" },
      { name: "วันเกิด", align: "left", key: "birthDate" },
      { name: "อายุ (ปี)", align: "left", key: "age" },
      { name: "ส่วนสูง (cm)", align: "center", key: "height" },
      { name: "น้ำหนัก (kg)", align: "center", key: "weight" },
      { name: "BMI", align: "center", key: "bmi" },
      { name: "Cancer Screen", align: "center", key: "cancerScreen" },
    ],
    data: rpt02SampleData,
  },
  {
    reportType: "RPT06",
    reportTitle: "รายงานข้อมูล",
    reportDescription: "",
    columns: [
      { name: "ลำดับ", align: "center", key: "order" },
      { name: "HN", align: "left", key: "hn" },
      { name: "ชื่อ-นามสกุล", align: "left", key: "name" },
      {
        name: "วันที่ Visit",
        align: "left",
        key: "vistDate",
      },
      { name: "โรคความดันโลหิตสูง (HT)", align: "center", key: "ht" },
      { name: "โรคเบาหวาน (DM)", align: "center", key: "dm" },
      { name: "โรคไขมันในเลือดสูง (DLP)", align: "center", key: "dlp" },
      {
        name: "ภาวะไขมันสะสมในตับ (NAFLD/NASH)",
        align: "center",
        key: "nafld",
      },
      { name: "โรคหลอดเลือดหัวใจ (CAD)", align: "center", key: "cad" },
      { name: "โรคหลอดเลือดสมอง (CVA)", align: "center", key: "cva" },
      {
        name: "โรคนิ่วในถุงน้ำดี (Gallstones)",
        align: "center",
        key: "gallstones",
      },
      {
        name: "โรคถุงน้ำผิดปกติที่รังไข่ (PCOS)",
        align: "center",
        key: "pcos",
      },
      { name: "โรคข้อเข่าเสื่อม", align: "center", key: "kneeOsteoarthritis" },
      { name: "โรคกระดูกสันหลัง", align: "center", key: "spondylosis" },
      { name: "โรคกรดไหลย้อน (GERD)", align: "center", key: "gerd" },
      { name: "โรคทางจิตเวช", align: "center", key: "psych" },
      { name: "ประวัติโรคมะเร็ง", align: "center", key: "caco" },
      {
        name: "ภาวะทางเดินหายใจอุดกั้นขณะนอนหลับ (OSA)",
        align: "center",
        key: "osa",
      },
      { name: "CPAP use", align: "center", key: "cpap" },
      { name: "โรคอื่นๆ ", align: "center", key: "other" },
    ],
    data: rpt06SampleData,
  },
];
