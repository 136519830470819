import {
  Avatar,
  Box,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Seemore } from "../../asset/iconSvg";
import { PersonIcon } from "../../icon";
import { body12400, body14500, title16600 } from "../../style";
import { PatientUser, PatientUserProfile } from "../../type";
import { calculateAge } from "../../utils/calculateAge";

type Props = {
  patientUser: PatientUser;
  patientUserProfile: PatientUserProfile;
  handelViewProfile: () => void;
};
export const ProfileCard = ({
  patientUser,
  patientUserProfile,
  handelViewProfile,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Paper elevation={4} sx={{ borderRadius: 2, height: "fit-content" }}>
      <Box sx={{ p: 2 }}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <div>
            <Typography style={title16600}>Profile</Typography>

            <Typography style={body14500} sx={{ color: "#B5B5C3" }}>
              ข้อมูลโปรไฟล์
            </Typography>
          </div>
          <Stack direction={"row"}>
            <Avatar sx={{ width: 48, height: 48 }}>
              <PersonIcon fontSize="medium" />
            </Avatar>
            <IconButton
              aria-describedby={id}
              // onClick={(e) => {
              //   setAnchorEl(e.currentTarget);
              // }}
              onClick={handelViewProfile}
            >
              <Seemore />
            </IconButton>
            {/* <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box onClick={handelViewProfile}>
                <Typography sx={{ p: 1, fontSize: 14 }}>ดูข้อมูล</Typography>
              </Box>
            </Popover> */}
          </Stack>{" "}
        </Box>
        <Stack spacing={1} sx={{ mt: 2 }}>
          <Box
            display={"grid"}
            gridTemplateColumns={"1fr 2fr"}
            sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
          >
            <Typography style={body12400} sx={{ color: "#646D78" }}>
              {patientUser.userType === "EVENT" ? `ID` : `HN`}
            </Typography>
            <Box>
              <Typography style={body12400} sx={{ color: "#272E39" }}>
                {patientUser.hn ? patientUser.hn?.replace(/^ID/, "") : "-"}
              </Typography>
            </Box>
          </Box>
          <Box
            display={"grid"}
            gridTemplateColumns={"1fr 2fr"}
            sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
          >
            <Typography style={body12400} sx={{ color: "#7E8299" }}>
              ชื่อ-นามสกุล
            </Typography>
            <Box>
              <Typography style={body12400} sx={{ color: "#272E39" }}>
                {`${patientUserProfile.firstName} ${patientUserProfile.lastName}`}
              </Typography>
            </Box>
          </Box>
          <Box
            display={"grid"}
            gridTemplateColumns={"1fr 2fr"}
            sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
          >
            <Typography style={body12400} sx={{ color: "#7E8299" }}>
              อายุ
            </Typography>
            <Box>
              <Typography style={body12400} sx={{ color: "#272E39" }}>
                {calculateAge(patientUserProfile.birthDate)}
              </Typography>
            </Box>
          </Box>
          <Box
            display={"grid"}
            gridTemplateColumns={"1fr 2fr"}
            sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
          >
            <Typography style={body12400} sx={{ color: "#7E8299" }}>
              โรงพยาบาล
            </Typography>
            <Box>
              <Typography style={body12400} sx={{ color: "#272E39" }}>
                {patientUserProfile.affiliatedHospital
                  ? patientUserProfile.affiliatedHospital
                  : "-"}
              </Typography>
            </Box>
          </Box>
          <Box
            display={"grid"}
            gridTemplateColumns={"1fr 2fr"}
            sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
          >
            <Typography style={body12400} sx={{ color: "#7E8299" }}>
              สิทธิ์การรักษา
            </Typography>
            <Box>
              <Typography style={body12400} sx={{ color: "#272E39" }}>
                {patientUserProfile.healthBenefit
                  ? patientUserProfile.healthBenefit === "อื่นๆ"
                    ? patientUserProfile.healthBenefitOther
                    : patientUserProfile.healthBenefit
                  : "-"}
              </Typography>
            </Box>
          </Box>
          <Box
            display={"grid"}
            gridTemplateColumns={"1fr 2fr"}
            sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
          >
            <Typography style={body12400} sx={{ color: "#7E8299" }}>
              โรคประจำตัว
            </Typography>
            <Box>
              <Typography style={body12400} sx={{ color: "#272E39" }}>
                {patientUserProfile.chronicDisease
                  ? patientUserProfile.chronicDisease
                      .map((item) =>
                        item === "โรคอื่นๆ"
                          ? `โรคอื่นๆ (${patientUserProfile.chronicDiseaseOther})`
                          : item === "โรคทางจิตเวช"
                          ? `โรคทางจิตเวช (${patientUserProfile.psychoticDisorderOther})`
                          : item === "โรคมะเร็ง"
                          ? `โรคมะเร็ง (${patientUserProfile.cancerOther})`
                          : `${item}`
                      )
                      .join(", ")
                  : "-"}
              </Typography>
            </Box>
          </Box>
        </Stack>
      </Box>
    </Paper>
  );
};
