import { Button } from "@mui/material";
import dayjs from "dayjs";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import excel_logo from "../asset/excellogo.png";

type Props = {
  data: any[];
  columns: any[];
  fileName: string;
  reportTitle: string;
  reportDescription: string;
};

const ExcelExport = ({
  data,
  fileName,
  columns,
  reportTitle,
  reportDescription,
}: Props) => {
  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");
    const endColumn = columns.length === 39 ? 'AM' : String.fromCharCode(65 + columns.length - 1);//  A = 65 in ASCII

    const headerRows = [
      {
        text: reportTitle,
        alignment: { vertical: "middle", horizontal: "center" },
        merge: { start: "A1", end: `${endColumn}1` },
        style: {
          bold: true,
          color: { argb: "098484" },
          size: 16,
          name: "TH SarabunPSK",
        },
      },
      {
        text: "",
        alignment: { vertical: "middle", horizontal: "center" },
        merge: { start: "A2", end: `${endColumn}2` },
        style: {
          bold: true,
          color: { argb: "098484" },
          size: 16,
          name: "TH SarabunPSK",
        },
      },
      {
        text: reportDescription,
        alignment: { vertical: "middle", horizontal: "center" },
        merge: { start: "A3", end: `${endColumn}3` },
        style: {
          size: 16,
          name: "TH SarabunPSK",
        },
      },
      {
        text: `วันที่ออกรายงาน: ${dayjs().format("DD/MM/BBBB, HH:mm น.")}`,
        alignment: { vertical: "middle", horizontal: "right" },
        merge: { start: "A4", end: `${endColumn}4` },
        style: {
          size: 16,
          name: "TH SarabunPSK",
        },
      },
      {
        text: `Total Records: ${data.length}`,
        alignment: { vertical: "middle", horizontal: "right" },
        merge: { start: "A5", end: `${endColumn}5` },
        style: {
          size: 16,
          name: "TH SarabunPSK",
        },
      },
    ];

    // Add header rows with merging
    headerRows.forEach((row) => {
      const rowIndex = worksheet.addRow([row.text]);
      const cell = worksheet.getCell(row.merge.start);
      cell.alignment = row.alignment as Partial<ExcelJS.Alignment>;
      cell.font = row.style;
      worksheet.mergeCells(row.merge.start, row.merge.end);
    });

    // Add column headers
    const headerNames = columns.map((col) => col.name);
    worksheet.addRow(headerNames);

    headerNames.forEach((name, colIndex) => {
      const cell = worksheet.getCell(6, colIndex + 1); // Row 5 for header
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "098484" },
      };
      cell.font = {
        color: { argb: "FFFFFF" },
        size: 16,
        name: "TH SarabunPSK",
        bold: true,
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    // Add the actual data
    data.forEach((item, rowIndex) => {
      const rowData = columns.map((col) => {
        const value = item[col.key];
        if (col.key === "order") {
          return rowIndex + 1;
        } else {
          return value === true ? "Yes" : value === false ? "No" : value;
        }
      });
      worksheet.addRow(rowData);
      // Apply style to each cell in the row
      rowData.forEach((_, colIndex) => {
        const cell = worksheet.getCell(rowIndex + 7, colIndex + 1);
        cell.font = {
          size: 16,
          name: "TH SarabunPSK",
        };
        cell.alignment = { vertical: "middle", horizontal: "left" };
      });
    });
    // Adjust column widths based on the content
    columns.forEach((col, colIndex) => {
      let maxLength = col.name.length;
      data.forEach((item) => {
        const value = item[col.key];
        const length = value ? value.toString().length : 0;
        if (length > maxLength) {
          maxLength = length;
        }
      });
      worksheet.getColumn(colIndex + 1).width = maxLength + 2; // Add some padding
    });

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${fileName}.xlsx`);
  };

  return (
    <Button
      variant="contained"
      onClick={exportToExcel}
      sx={{ display: "flex", gap: 1, bgcolor: "#1FB141", p: 1 }}
    >
      <img src={excel_logo} width={25} />
      Export
    </Button>
  );
};

export default ExcelExport;
